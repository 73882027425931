import React, { useEffect, useState } from 'react';
import { ProgressBar, Typography, useTheme } from 'design-system';
import { format, isPast, subMinutes } from 'date-fns';

interface ReservationTimerProps {
  endTime: Date;
}

/**
 * Calculates the progress of a timer.
 *
 * @param endTime datetime ISO string or Date object or timestamp
 * @param startTime datetime ISO string or Date object or timestamp
 * @returns decimal 0-100 progress value and "time remaining" UI label.
 */
const calculateProgress = (
  endTime: string | Date | number,
  startTime: string | Date | number,
) => {
  const nowInMs = new Date().getTime();
  const endTimeInMs = new Date(endTime).getTime();
  const startTimeInMs = new Date(startTime).getTime();

  const totalDuration = endTimeInMs - startTimeInMs;
  const timeElapsed = nowInMs - startTimeInMs;
  const totalRemaining = endTimeInMs - nowInMs;

  if (nowInMs >= endTimeInMs) {
    return {
      progress: 0,
      timeRemaining: '00:00',
    };
  } else {
    const progressValue = (timeElapsed / totalDuration) * 100;
    return {
      progress: 100 - progressValue,
      timeRemaining: format(totalRemaining, 'mm:ss'),
    };
  }
};

const ReservationTimer: React.FC<ReservationTimerProps> = ({ endTime }) => {
  const theme = useTheme();

  const startTime = subMinutes(endTime, 25).getTime();
  const [{ progress, timeRemaining }, setTimerState] = useState(() =>
    calculateProgress(endTime, startTime),
  );

  useEffect(() => {
    const updateTimer = () => {
      const newState = calculateProgress(endTime, startTime);
      setTimerState(newState);

      if (isPast(new Date(endTime))) {
        clearInterval(interval);
      }
    };

    const interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval);
  }, [startTime, endTime]);

  return (
    <>
      <Typography
        variant="Body M"
        typography={{
          xs: 'Body S',
          sm: 'Body M',
        }}
      >
        Your place on this trip will be reserved for {timeRemaining} mins
      </Typography>
      <ProgressBar
        value={progress}
        showValue={false}
        barColor={theme.palette.common.black}
      />
    </>
  );
};

export default ReservationTimer;
