import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';

import { JoinWhatsApp } from './JoinWhatsApp';

type PropTypes = FieldRenderProps<boolean>;

export const JoinWhatsAppFormField: React.FC<PropTypes> = ({ input }) => {
  return (
    <JoinWhatsApp {...input} checked={Boolean(input.checked)} onChange={input.onChange} />
  );
};
