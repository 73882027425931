import { CssBaseline, GlobalStyles } from '@mui/material';
import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { CheckoutAppRouter } from './CheckoutAppRouter';
import { GlobalErrorBoundary, flashPackTheme } from 'design-system';
import { apolloClient } from '@src/apolloClient';
import { globalStyles } from './GlobalStyle';
import { initializeFirebase } from './firebase';
import { ApolloProvider } from '@apollo/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { AuthorizationProvider } from '@src/auth/AuthorizationProvider';
import { AuthenticationProvider } from '@src/auth/AuthenticationProvider';
import { Snackbar } from './Snackbar';

initializeFirebase();

function App() {
  return (
    <GlobalErrorBoundary>
      <StyledEngineProvider injectFirst>
        <ApolloProvider client={apolloClient}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiThemeProvider theme={flashPackTheme}>
              <AuthenticationProvider>
                <AuthorizationProvider>
                  <CssBaseline />
                  <GlobalStyles styles={globalStyles} />
                  <CheckoutAppRouter />
                  <Snackbar limit={3} data-testid="toast" />
                </AuthorizationProvider>
              </AuthenticationProvider>
            </MuiThemeProvider>
          </LocalizationProvider>
        </ApolloProvider>
      </StyledEngineProvider>
    </GlobalErrorBoundary>
  );
}

export default App;
