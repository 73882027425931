import React from 'react';
import { BaseInput, BaseInputProps } from '../BaseInput';

export const numberInputChangeHandler = (
  e: React.ChangeEvent<HTMLInputElement>,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
) => {
  const numericValue = e.target.value.replace(/[^0-9]/g, '');

  const maxLength = e.target.maxLength;

  if (maxLength !== -1 && numericValue.length > maxLength) {
    e.target.value = numericValue.slice(0, maxLength);
  } else {
    e.target.value = numericValue;
  }

  if (onChange) {
    onChange(e);
  }
};

export const NumberInput: React.FC<BaseInputProps> = (props) => {
  const { onChange } = props;

  return (
    <BaseInput
      inputProps={{
        ...props.inputProps,
        type: 'number',
      }}
      {...props}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        numberInputChangeHandler(e, onChange)
      }
      onInput={(e: React.FormEvent<HTMLInputElement>) => {
        numberInputChangeHandler(e as React.ChangeEvent<HTMLInputElement>, onChange);
      }}
    />
  );
};
