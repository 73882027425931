import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Box,
  LoadingButton,
  ProgressBar,
  Stack,
  Typography,
  useTheme,
} from 'design-system';

import Layout from '@src/shared/Layout/Layout';
import { UpdateBasketDocument } from '@flashpack/graphql';
import { useBasket, useRouting } from '@src/shared/hooks';
import { Contacts } from '@src/shared/contacts/Contacts';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';
import { hasAnyErrorWithCode } from '@src/shared/utils/errorUtils';
import { useSoldOutRedirect } from '@src/shared/hooks/useSoldOutRedirect';
import { HeroHeader } from '@src/shared/hero-header/HeroHeader';

export const ReservationExpiredPage: React.FC = () => {
  const theme = useTheme();

  const [isRetryLoading, setIsRetryLoading] = useState(false);

  const { queryParams, navigate } = useRouting<{
    departureCode: string;
    currencyCode: string;
  }>();
  const { departureCode } = queryParams;
  const { basket, loading: basketLoading } = useBasket();
  const { runRedirect: redirectToSoldOutPage } = useSoldOutRedirect();

  const [updateBasket] = useMutation(UpdateBasketDocument);

  const handleUpdateBasket = useCallback(async () => {
    setIsRetryLoading(true);
    if (!basket) {
      return;
    }
    try {
      const updatedBasket = await updateBasket({
        variables: {
          input: {
            id: basket.id,
            departureCode,
            updateReservation: true,
          },
        },
      });
      if (updatedBasket) {
        navigate(CheckoutRoutePath.BOOK_DEPARTURE.value, { preserveExisting: true });
      }
    } catch (error) {
      setIsRetryLoading(false);
      if (hasAnyErrorWithCode(error, 'TRIP_SOLD_OUT')) {
        redirectToSoldOutPage();
      } else {
        throw error;
      }
    }
  }, [basket, departureCode, updateBasket, navigate, redirectToSoldOutPage]);

  const handleRetryClick = useCallback(() => {
    void (async () => {
      await handleUpdateBasket();
    })();
  }, [handleUpdateBasket]);

  return (
    <Layout HeroComponent={<HeroHeader text="Your reservation has expired" />}>
      <Stack gap={2} maxWidth={610} alignItems={'center'} margin={'0 auto'}>
        <Typography variant="Body M">
          <b>00:00</b> mins
        </Typography>
        <Box width={'100%'}>
          <ProgressBar
            showValue={false}
            value={100} // workaround not to override the ProgressBar color
            barColor={theme.palette.principal.grey50}
          />
        </Box>
        <Typography
          whiteSpace={'break-spaces'}
          variant="H5"
          typography={{
            xs: 'H6',
            sm: 'H5',
          }}
        >
          We’re sorry, we can only reserve bookings for 25 minutes.{'\n'}
          After this time we have to make the slot available again.
        </Typography>
        <Typography
          variant="Body M"
          typography={{
            xs: 'Body S',
            sm: 'Body M',
          }}
        >
          Don’t worry, the slot may still be available!
        </Typography>
        <LoadingButton
          variant={'contained'}
          onClick={handleRetryClick}
          disabled={basketLoading}
          loading={isRetryLoading}
        >
          Try again
        </LoadingButton>

        <Box mt={7}>
          <Contacts />
        </Box>
      </Stack>
    </Layout>
  );
};
