import { Box, Grid } from 'design-system';
import React, { ReactNode } from 'react';

interface PropTypes {
  mainSection: null | ReactNode | ReactNode[];
  additionalSection: null | ReactNode | ReactNode[];
}

/**
 * A responsive layout container.
 * It displays two sections side by side on desktop
 * or one below the other on mobile.
 *
 * Supposed to be used at a page level where you have two sections.
 */
export const ResponsiveSideBySide: React.FC<PropTypes> = ({
  mainSection,
  additionalSection,
}) => {
  return (
    <Grid
      container
      justifyContent={'space-between'}
      spacing={{
        // in mobile, it's a vertical gap;
        // should be the same as the gap of the parent Stack
        xs: 4,
        sm: 4,
        // in desktop, it's a horizontal gap;
        // should be a bit larger
        md: 8,
      }}
    >
      {mainSection && (
        <Grid
          item
          component={Box}
          xs={12}
          sm={12}
          md={7}
          // whenever possible, make the main section grow
          // (e.g. when there's no additional section)
          flexGrow={1}
        >
          {mainSection}
        </Grid>
      )}

      {additionalSection && (
        <Grid item component={Box} xs={12} sm={12} md={5}>
          {additionalSection}
        </Grid>
      )}
    </Grid>
  );
};
