import { Dialog, DialogTitle, IconButton, Typography } from '@mui/material';

import { useMutation } from '@apollo/client';
import {
  AirwallexDropInElement,
  CreatedPaymentIntent,
} from '../airwallex/AirwallexDropIn';
import * as Sentry from '@sentry/browser';
import { CreatePaymentIntentDocument, CustomerCurrency } from '@flashpack/graphql';
import {
  GenericError,
  Stack,
  useTheme,
  useMediaQuery,
  CircleCloseIcon,
  MobileModal,
} from 'design-system';
import { useCallback, useEffect, useState } from 'react';
import { TotalToPay } from './TotalToPay';
import ReservationTimer from '@src/book-departure/reservation-timer/ReservationTimer';
import { useBasket, useExpiredReservationRedirect, useRouting } from '@src/shared/hooks';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';

export const PaymentModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [createPaymentIntent] = useMutation(CreatePaymentIntentDocument);
  const [amountToPay, setAmountToPay] = useState<number | null>(null);

  const { queryParams, navigate } = useRouting<{
    departureCode: string;
    currencyCode: string;
  }>();
  const { currencyCode } = queryParams;

  const { basket, loading, error } = useBasket();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const customerCurrency = currencyCode.toLowerCase() as CustomerCurrency;

  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [paymentError, setPaymentError] = useState(false);

  const handleAirwallexSuccess = useCallback(() => {
    setPaymentSuccessful(true);
  }, []);

  const handleAirwallexError = useCallback((error: unknown) => {
    Sentry.captureMessage('Airwallex payment failed', {
      level: 'fatal',
      extra: { error: JSON.stringify(error) },
    });
    setPaymentError(true);
  }, []);

  useExpiredReservationRedirect({
    skip: loading || !basket?.reservation,
    reservationExpiryDate: basket?.reservation?.expiresAt,
  });

  useEffect(() => {
    if (paymentSuccessful) {
      navigate(CheckoutRoutePath.PAYMENT_SUCCESS.value, { preserveExisting: true });
    }
  }, [paymentSuccessful, navigate]);

  if (error || !basket) {
    return (
      <GenericError
        error={'Something went wrong while creating your order. Please try again later.'}
      />
    );
  }

  const createIntent = async (): Promise<CreatedPaymentIntent> => {
    const { data: paymentIntent } = await createPaymentIntent({
      variables: {
        input: {
          amount: basket.tripTotal, // todo: remove it; take the value in the server
          currency: customerCurrency,
          basketId: basket.id,
        },
      },
    });
    if (paymentIntent) {
      setAmountToPay(paymentIntent.createPaymentIntent.amount);
      return paymentIntent.createPaymentIntent as CreatedPaymentIntent;
    }
    throw new Error('Failed to create intent!');
  };

  return (
    <ModalComponent open={open} onClose={onClose}>
      <Stack gap={3} padding={isSmallScreen ? 1 : 0}>
        {!isSmallScreen && (
          <DialogTitle sx={{ m: 0, p: 0 }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h3">Payment</Typography>
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  top: -4,
                  color: (theme) => theme.palette.grey[600],
                }}
              >
                <CircleCloseIcon sx={{ width: 32, height: 32 }} />
              </IconButton>
            </Stack>
          </DialogTitle>
        )}
        <Stack gap={1}>
          {basket.reservation && (
            <ReservationTimer endTime={new Date(basket.reservation.expiresAt)} />
          )}
        </Stack>

        <TotalToPay
          currency={customerCurrency}
          finalCost={amountToPay}
          selectedPaymentOption={basket.selectedPaymentOption}
        />
        <AirwallexDropInElement
          onSuccess={handleAirwallexSuccess}
          onError={handleAirwallexError}
          createIntent={createIntent}
          dropInType="payment"
        />
        {!!paymentError && (
          <GenericError
            error={
              'Something went wrong when processing your payment. Please try again later.'
            }
          />
        )}
      </Stack>
    </ModalComponent>
  );
};

type ModalComponentProps = {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
};

const ModalComponent = ({ children, open, onClose }: ModalComponentProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  if (isSmallScreen) {
    return (
      <MobileModal open={open} onClose={onClose} title="Payment">
        {children}
      </MobileModal>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth PaperProps={{ sx: { padding: 3 } }}>
      {children}
    </Dialog>
  );
};
