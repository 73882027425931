import { useQuery } from '@apollo/client';
import { CustomerCurrency, OrderDetailsQueryDocument } from '@flashpack/graphql';
import { useRouting } from '@src/shared/hooks/useRouting';

export const useOrderDetails = () => {
  const { queryParams } = useRouting<{ departureCode: string; currencyCode: string }>();
  const { departureCode, currencyCode } = queryParams;
  const customerCurrencyCode = currencyCode?.toLowerCase();

  const response = useQuery(OrderDetailsQueryDocument, {
    variables: {
      departureCode,
      currency: customerCurrencyCode as CustomerCurrency,
    },
    skip: !departureCode || !customerCurrencyCode,
  });

  return response;
};
