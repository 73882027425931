import React, { useEffect, useState } from 'react';
import {
  AirwallexEnv,
  PaymentMethodType,
  createElement,
  loadAirwallex,
} from 'airwallex-payment-elements';
import { Environment, currentEnvironment } from '@src/environment';
import * as Sentry from '@sentry/react';
import { Skeleton, Stack } from 'design-system';

export type CreatedIntent = {
  clientSecret: string;
  currency: string;
  customerId: string;
};

export type CreatedPaymentIntent = CreatedIntent & {
  id: string;
};

interface BaseProps {
  onSuccess: () => void;
  onError: (e: unknown) => void;
}

interface PaymentProps extends BaseProps {
  dropInType: 'payment';
  createIntent: () => Promise<CreatedPaymentIntent>;
}

interface UpdateProps extends BaseProps {
  dropInType: 'update';
  createIntent: () => Promise<CreatedIntent>;
}

type PropTypes = PaymentProps | UpdateProps;

export const AirwallexDropInElement: React.FC<PropTypes> = (props) => {
  const dropInId = 'airwallex-drop-in';

  const [loading, setLoading] = useState<boolean>(true);

  const { createIntent, onError, onSuccess } = props;

  const getAirwallexEnv = (): AirwallexEnv => {
    const env = currentEnvironment;

    if (env === Environment.Production) {
      return 'prod';
    } else {
      return 'demo';
    }
  };

  const getPaymentMethodsForEnv = (): PaymentMethodType[] => {
    return ['card', 'googlepay', 'applepay'];
  };

  useEffect(() => {
    const loadDropInElement = async () => {
      try {
        const [intent] = await Promise.all([
          createIntent(),
          // TODO: look into loading airwallex even earlier in the app lifecycle
          loadAirwallex({
            env: getAirwallexEnv(), //'local' | 'dev' | 'staging' | 'demo' | 'prod';
            origin: window.location.origin,
          }),
        ]);

        const { clientSecret, currency, customerId } = intent;
        const id = 'id' in intent ? (intent.id as string) : undefined;

        const element = createElement('dropIn', {
          methods: getPaymentMethodsForEnv(),
          mode: 'recurring',
          recurringOptions: {
            next_triggered_by: 'merchant',
            merchant_trigger_reason: 'scheduled',
            card: {
              currency: currency.toUpperCase(),
            },
            currency: currency.toUpperCase(),
          },
          customer_id: customerId,
          intent_id: props.dropInType === 'payment' ? id : undefined,
          client_secret: clientSecret,
          currency: currency.toUpperCase(),
          googlePayRequestOptions: {
            buttonType: 'pay',
            countryCode: 'GB',
          },
          // apple pay request options are required to enable apple pay
          applePayRequestOptions: {
            countryCode: 'GB',
          },
          style: {
            // the 3ds popup window dimension
            popupWidth: 400,
            popupHeight: 549,
          },
        });

        element?.mount(dropInId); // This 'dropIn' id MUST MATCH the id on your empty container created in Step 4
      } catch (error) {
        Sentry.captureMessage('Error loading Airwallex DropIn Element', {
          level: 'error',
          extra: {
            error,
          },
        });
      } finally {
        setLoading(false);
      }
    };

    void loadDropInElement();

    const domElement = document.getElementById(dropInId);

    domElement?.addEventListener('onReady', onReady as EventListener);
    domElement?.addEventListener('onSuccess', onSuccessListener as EventListener);
    domElement?.addEventListener('onError', onErrorListener as EventListener);

    return () => {
      domElement?.removeEventListener('onReady', onReady as EventListener);
      domElement?.removeEventListener('onSuccess', onSuccessListener as EventListener);
      domElement?.removeEventListener('onError', onErrorListener as EventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // This effect should ONLY RUN ONCE as we do not want to reload Airwallex and remount the elements

  // STEP #6: Add an event listener to handle events when the element is mounted
  const onReady = (_event: CustomEvent): void => {};

  // STEP #7: Add an event listener to handle events when the payment is successful.
  const onSuccessListener = (_event: CustomEvent): void => {
    /**
     * Handle events on success
     */
    onSuccess();
  };

  // STEP #8: Add an event listener to handle events when the payment has failed.
  const onErrorListener = (event: CustomEvent) => {
    /**
     * Handle events on error
     */
    onError(event?.detail);
  };

  // Example: Custom styling for the dropIn container, can be placed in css
  const containerStyle = {
    width: '100%',
  };

  return (
    <div>
      {loading && (
        <Stack gap={3}>
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: '10px' }}
            height={40}
            width="100%"
            animation={'pulse'}
          />
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: '10px' }}
            height={40}
            width="100%"
            animation={'pulse'}
          />
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: '10px' }}
            height={40}
            width="100%"
            animation={'pulse'}
          />
        </Stack>
      )}
      <div
        id={dropInId}
        style={{
          ...containerStyle,
        }}
      />
    </div>
  );
};
