import { useMutation } from '@apollo/client';
import { LoginWithEmailAndPasswordDocument } from '@flashpack/graphql';
import { Stack, Typography } from '@mui/material';
import { useCustomerAuthorization } from '@src/auth/AuthorizationProvider';
import { useFirebaseAuth } from '@src/auth/useAuthentication';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';
import { ImageSplash } from '@src/shared/ImageSplash/ImageSplash';
import Layout from '@src/shared/Layout/Layout';
import BookDepartureHeader from '@src/shared/book-departure-header/BookDepartureHeader';
import { useRouting } from '@src/shared/hooks';
import { useOrderDetails } from '@src/shared/hooks/useOrderDetails';
import { OrderDetails } from '@src/shared/order-details/OrderDetails';
import StepperPill from '@src/shared/stepper-pill/StepperPill';
import {
  Box,
  FormTextInput,
  GenericError,
  LoadingButton,
  Validator,
  composeValidators,
  useTheme,
} from 'design-system';
import { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { Navigate, useLocation, Link } from 'react-router-dom';
import { PageFormTitle } from '@src/shared/page-form-title/PageFormTitle';
import { ResponsiveSideBySide } from '@src/shared/responsive-side-by-side/ResponsiveSideBySide';
import { PageFormLoader } from '@src/shared/page-form-loader/PageFormLoader';
import { trackCheckoutEvent } from '@src/gtm/tracking';

type LogInForm = {
  email: string;
  password: string;
};

type LocationState = null | {
  redirectBack?: string;
};

const passwordResetLink = 'https://bookings.flashpack.com/password/reset';

export const LogInPage = () => {
  const theme = useTheme();
  const { queryParams } = useRouting<{
    departureCode: string;
    currencyCode: string;
  }>();
  const { data, loading } = useOrderDetails();
  const location = useLocation();

  const isPartOfBookingFlow = Boolean(
    queryParams.currencyCode && queryParams.departureCode,
  );
  const { currentUser, loadingAuthorization } = useCustomerAuthorization();

  const [loginUser, { error: loginError }] = useMutation(
    LoginWithEmailAndPasswordDocument,
  );

  const [error, setError] = useState<string | null>(null);

  const { signInWithEmailAndPassword } = useFirebaseAuth();

  useEffect(() => {
    if (
      !loading &&
      isPartOfBookingFlow &&
      data?.orderDetails &&
      queryParams.currencyCode
    ) {
      trackCheckoutEvent(data?.orderDetails, queryParams.currencyCode);
    }
  }, [loading, isPartOfBookingFlow, data?.orderDetails, queryParams.currencyCode]);

  if (loadingAuthorization) {
    return (
      <Layout HeroComponent={<BookDepartureHeader />}>
        <PageFormLoader />
      </Layout>
    );
  }

  if (currentUser) {
    const defaultRedirectBack = isPartOfBookingFlow
      ? CheckoutRoutePath.BOOK_DEPARTURE.value + location.search
      : CheckoutRoutePath.MY_ACCOUNT_DETAILS.value;

    const redirectBack =
      (location?.state as LocationState)?.redirectBack ?? defaultRedirectBack;
    return <Navigate to={redirectBack} />;
  }

  const onSubmit = async (values: LogInForm) => {
    const { data } = await loginUser({
      variables: {
        email: values.email,
        password: values.password,
      },
    });
    if (data?.loginWithEmailAndPassword) {
      await signInWithEmailAndPassword({
        email: values.email,
        password: values.password,
      });
    }
  };

  if (loginError && !error) {
    setError(loginError.message);
  }

  return (
    <Layout
      HeroComponent={
        isPartOfBookingFlow && (
          <BookDepartureHeader location={data?.orderDetails.location} />
        )
      }
    >
      <ResponsiveSideBySide
        mainSection={
          <Stack gap={3}>
            {isPartOfBookingFlow && <StepperPill currentStep={1} totalSteps={3} />}

            <Stack gap={2}>
              <PageFormTitle>Welcome back</PageFormTitle>
              <Form<LogInForm>
                onSubmit={onSubmit}
                render={({ handleSubmit, submitting }) => {
                  return (
                    <form
                      id="checkout-login"
                      onSubmit={(e) => {
                        void handleSubmit();
                        e?.preventDefault();
                      }}
                    >
                      <Stack gap={3}>
                        <Box
                          sx={{
                            padding: 1,
                            backgroundColor: theme.palette.brand.sky,
                            borderRadius: 0.5,
                            width: 'fit-content',
                          }}
                        >
                          <Typography variant="Body S">
                            {"Don't yet have an account with Flash Pack? "}
                            <Link
                              to={
                                CheckoutRoutePath.REGISTER.value + window.location.search
                              }
                              style={{ color: 'inherit' }}
                              state={{
                                redirectBack: (location?.state as LocationState)
                                  ?.redirectBack,
                              }}
                            >
                              Sign up
                            </Link>
                          </Typography>
                        </Box>
                        <FormTextInput
                          name="email"
                          validate={composeValidators(
                            Validator.required,
                            Validator.validEmail,
                          )}
                          textInputProps={{
                            placeholder: 'Enter email...',
                            label: 'Email Address',
                            testid: 'login-email',
                            size: 'large',
                          }}
                        />
                        <FormTextInput
                          name="password"
                          validate={composeValidators(
                            Validator.required,
                            Validator.validPassword,
                          )}
                          textInputProps={{
                            placeholder: 'Enter password...',
                            label: 'Password',
                            testid: 'login-password',
                            type: 'password',
                            size: 'large',
                          }}
                        />
                        <Typography variant="Body S" textAlign={'left'}>
                          Forgotten your password?{' '}
                          <Link
                            target="_blank"
                            to={passwordResetLink}
                            style={{ color: 'inherit' }}
                          >
                            Click here
                          </Link>
                        </Typography>
                        {error && <GenericError error={error} />}
                        <LoadingButton
                          loading={submitting}
                          disabled={submitting}
                          type="submit"
                          variant="contained"
                          data-testid="login-button"
                          sx={{ width: 'fit-content' }}
                        >
                          Login and continue
                        </LoadingButton>
                      </Stack>
                    </form>
                  );
                }}
              />
            </Stack>
          </Stack>
        }
        additionalSection={
          <Box>
            {isPartOfBookingFlow ? (
              <OrderDetails loading={loading} orderDetails={data?.orderDetails} />
            ) : (
              <ImageSplash />
            )}
          </Box>
        }
      />
    </Layout>
  );
};
