import { useMutation, useQuery } from '@apollo/client';
import {
  BookingDetailsDocument,
  CreateBalancePaymentIntentDocument,
  CustomerCurrency,
  PaymentOptionType,
} from '@flashpack/graphql';
import {
  AirwallexDropInElement,
  CreatedPaymentIntent,
} from '@src/airwallex/AirwallexDropIn';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import {
  BreadCrumbs,
  composeValidators,
  FormNumberInput,
  GenericError,
  LoadingButton,
  RadioAccordionGroup,
  RadioAccordionGroupOption,
  Skeleton,
  Stack,
  Typography,
  useTheme,
  Validator,
  ValidatorFactory,
} from 'design-system';
import { useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { formatCurrency } from '../shared/utils/currencyUtils';
import { BalancePaymentSuccess } from '@src/balance-payment/BalancePaymentSuccess';
import { Link } from 'react-router-dom';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';
import { format, subDays } from 'date-fns';

enum Step {
  SelectPaymentOption = 'SelectPaymentOption',
  MakePayment = 'MakePayment',
  PaymentSuccess = 'PaymentSuccess',
}

enum BalancePaymentOptionType {
  RemainingBalance = 'Remaining Balance',
  Installment = 'Installment',
}

type FormValues = {
  selectedPaymentOption: BalancePaymentOptionType;
  customAmount: number | null;
};

export const BalancePayment = () => {
  const [amount, setAmount] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [step, setStep] = useState<Step>(Step.SelectPaymentOption);
  const theme = useTheme();

  const [createBalancePaymentIntent, { error: createIntentError }] = useMutation(
    CreateBalancePaymentIntentDocument,
  );
  const { tripId } = useRequiredParams(['tripId']);

  const {
    data,
    loading,
    error: loadBookingDetailsError,
  } = useQuery(BookingDetailsDocument, {
    variables: {
      input: {
        id: parseInt(tripId),
      },
    },
  });

  const createIntent = async (): Promise<CreatedPaymentIntent> => {
    if (amount && data?.bookingDetails.id) {
      const intent = await createBalancePaymentIntent({
        variables: {
          input: {
            amount: amount,
            bookingId: data?.bookingDetails.id,
          },
        },
      });

      return intent.data?.createBalancePaymentIntent as CreatedPaymentIntent;
    }
    setError('Unexpected error occurred, please try again later.');

    throw new Error('Amount or booking ID is not set');
  };

  const onSubmit = (values: FormValues) => {
    if (values.selectedPaymentOption === BalancePaymentOptionType.Installment) {
      setAmount(values.customAmount);
    } else {
      setAmount(data?.bookingDetails.paymentInformation.remainingAmount || null);
    }
    setStep(Step.MakePayment);
  };

  const handleAirwallexSuccess = () => {
    setStep(Step.PaymentSuccess);
  };

  const handleAirwallexError = () => {
    setError('Something went wrong while making your payment. Please try again later.');
  };

  const options = useMemo(() => {
    if (!data) {
      return [];
    }

    const { paymentInformation } = data.bookingDetails;
    return [
      {
        value: BalancePaymentOptionType.RemainingBalance,
        label: 'Pay off remaining balance now',
        content: null,
        endAdornment: (
          <Typography variant="H6">
            {formatCurrency(
              paymentInformation.remainingAmount,
              paymentInformation.currencyCode as CustomerCurrency,
            )}
          </Typography>
        ),
      },
      {
        value: BalancePaymentOptionType.Installment,
        label: 'Pay an instalment towards your remaining balance',
        content: (
          <div>
            <Typography variant="Body M" sx={{ mb: 2 }}>
              Choose an amount to pay today that will be taken off your remaining balance.
            </Typography>
            <FormNumberInput
              name="customAmount"
              validate={composeValidators(
                Validator.required,
                ValidatorFactory.createMaxNumber(
                  data.bookingDetails.paymentInformation.remainingAmount,
                ),
              )}
              numberInputProps={{
                label: 'Choose payment amount *',
                placeholder: 'Enter amount...',
              }}
            />
          </div>
        ),
      },
    ];
  }, [data]);

  if (loading) {
    return <Skeleton variant="rounded" height={400} />;
  }

  if (loadBookingDetailsError || createIntentError || error || !data) {
    return (
      <GenericError error={error || 'Something went wrong. Please try again later.'} />
    );
  }

  const { trip } = data.bookingDetails;

  const balanceSettled = data.bookingDetails.paymentInformation.remainingAmount <= 0;

  const BNPLPaymentDate = format(
    subDays(new Date(data.bookingDetails.trip.dateFrom), 90),
    'dd MMM yyyy',
  );

  const isPaymentPlan =
    data.bookingDetails.paymentInformation.paymentOption ===
      PaymentOptionType.SixMonthsPlan ||
    data.bookingDetails.paymentInformation.paymentOption ===
      PaymentOptionType.ThreeMonthsPlan;

  return (
    <Stack>
      <Stack>
        <BreadCrumbs separator="›" sx={{ mb: 5 }}>
          <Typography variant="Body S">
            <Link
              to={CheckoutRoutePath.MY_TRIPS.value}
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              Your Trips
            </Link>
          </Typography>
          <Typography variant="Body S">
            <Link
              to={CheckoutRoutePath.BOOKING_DETAILS.generatePath(tripId)}
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              {data.bookingDetails.trip.country}
            </Link>
          </Typography>
          <Typography variant="Body S" color={theme.palette.common.black}>
            Make a payment
          </Typography>
        </BreadCrumbs>
        <Typography variant="H2" sx={{ mb: 5 }}>
          {trip.name}
        </Typography>
        {balanceSettled && (
          <Typography variant="Body S">Your balance is already settled.</Typography>
        )}
        {!balanceSettled && step === Step.SelectPaymentOption && (
          <SelectPaymentOption
            isPaymentPlan={isPaymentPlan}
            onSubmit={onSubmit}
            options={options}
            balanceSettlementDate={BNPLPaymentDate}
          />
        )}
        {step === Step.MakePayment && (
          <Stack maxWidth="sm">
            <AirwallexDropInElement
              dropInType="payment"
              onSuccess={handleAirwallexSuccess}
              onError={handleAirwallexError}
              createIntent={createIntent}
            />
          </Stack>
        )}
        {step === Step.PaymentSuccess && <BalancePaymentSuccess />}
      </Stack>
    </Stack>
  );
};

type SelectPaymentOptionProps = {
  onSubmit: (values: FormValues) => void;
  options: RadioAccordionGroupOption[];
  balanceSettlementDate: string;
  isPaymentPlan: boolean;
};

const SelectPaymentOption = ({
  isPaymentPlan,
  onSubmit,
  options,
  balanceSettlementDate,
}: SelectPaymentOptionProps) => {
  return (
    <Stack maxWidth="sm">
      <Typography variant="H5" sx={{ mb: 1 }}>
        Make a payment
      </Typography>
      {isPaymentPlan && (
        <Typography variant="Body S" sx={{ mb: 3 }}>
          Your payment plan is in progress. Settle your balance now or pay a one off
          instalment to reduce your future auto debit payments below.
        </Typography>
      )}
      {!isPaymentPlan && (
        <Typography variant="Body S" sx={{ mb: 3 }}>
          Your balance will automatically be settled on {balanceSettlementDate}. If you’d
          like to settle it earlier or pay something towards it now, choose from the
          options below.
        </Typography>
      )}
      <Form<FormValues>
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, form }) => {
          const handleOptionChange = (value: string) => {
            form.change('selectedPaymentOption', value as BalancePaymentOptionType);
            if (value === BalancePaymentOptionType.RemainingBalance) {
              form.change('customAmount', null);
            }
          };

          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                void handleSubmit();
              }}
            >
              <RadioAccordionGroup
                defaultValue={BalancePaymentOptionType.RemainingBalance}
                options={options}
                onSelectedChange={handleOptionChange}
              />
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                data-testid="continue-to-payment"
                sx={{ marginTop: '16px', alignSelf: 'self-start' }}
                loading={submitting}
              >
                Continue to payment
              </LoadingButton>
            </form>
          );
        }}
      />
    </Stack>
  );
};
