import { Link, Stack } from 'design-system';
import React from 'react';

export type SocialMediaLink = {
  icon: React.ReactNode;
  linkHref: string;
};

type SocialMediaLinksProps = {
  socialMediaLinks: Array<SocialMediaLink>;
};

export const SocialMediaLinks: React.FC<SocialMediaLinksProps> = ({
  socialMediaLinks,
}) => {
  return (
    <Stack
      gap={{
        xs: 2,
        sm: 1,
        md: 2,
      }}
      direction={'row'}
    >
      {socialMediaLinks.map((socialMediaLink) => (
        <Link
          href={socialMediaLink.linkHref}
          key={socialMediaLink.linkHref}
          sx={{ textDecoration: 'none' }}
        >
          {socialMediaLink.icon}
        </Link>
      ))}
    </Stack>
  );
};
