import { AirwallexDropInElement } from '@src/airwallex/AirwallexDropIn';
import { useState } from 'react';
import { Typography } from '@mui/material';
import { Form } from 'react-final-form';
import { Button, Container, FreeTextFormField, Stack } from 'design-system';
import { useRouting } from '@src/shared/hooks';

export const TestPayPage = () => {
  const [success, setSuccess] = useState(false);
  const [values, setValues] = useState<Record<string, string> | null>(null);
  const { queryParams } = useRouting<{
    id: string;
    clientSecret: string;
    currency: string;
    customerId: string;
  }>();

  const createPaymentIntent = () => Promise.resolve(queryParams);

  if (!values) {
    return (
      <Container maxWidth="sm">
        <h1>Test Pay Page</h1>
        <Form
          initialValues={{ ...queryParams }}
          onSubmit={(values) => {
            setValues(values);
          }}
          render={({ handleSubmit }) => {
            return (
              <form
                id="test-payment"
                onSubmit={(e) => {
                  void handleSubmit();
                  e?.preventDefault();
                }}
              >
                <Stack gap={3} marginBottom={3.5}>
                  <FreeTextFormField name="id" placeholder="id" />
                  <FreeTextFormField name="clientSecret" placeholder="Secret" />
                  <FreeTextFormField name="currency" placeholder="Currency (e.g. USD)" />
                  <Button variant="contained" type="submit">
                    Initalize
                  </Button>
                </Stack>
              </form>
            );
          }}
        />
      </Container>
    );
  }

  if (success) {
    return <Typography variant="H5">Payment successful</Typography>;
  }

  return (
    <AirwallexDropInElement
      createIntent={createPaymentIntent}
      onError={(e) => {
        console.error(e);
      }}
      onSuccess={() => {
        setSuccess(true);
      }}
      dropInType="payment"
    />
  );
};
