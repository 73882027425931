import { Box, useMediaQuery } from '@mui/system';
import React from 'react';
import loginImage1 from './login-image-1.png';
import loginImage2 from './login-image-2.png';
import loginImage3 from './login-image-3.png';
import loginImage4 from './login-image-4.png';
import loginImage5 from './login-image-5.png';
import { useTheme } from 'design-system';

export const ImageSplash: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box maxWidth={'350px'} marginTop={isMobile ? 6 : 0}>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
        <img
          src={loginImage1}
          alt="Image 1"
          width={'48%'}
          style={{ borderRadius: '12px', objectFit: 'cover' }}
        />

        <img
          src={loginImage2}
          alt="Image 2"
          width={'48%'}
          style={{ borderRadius: '12px', objectFit: 'cover' }}
        />
      </Box>
      {!isMobile && (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginY: 2 }}>
            <img
              src={loginImage3}
              alt="Image 3"
              width={'100%'}
              style={{ borderRadius: '12px', objectFit: 'cover' }}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <img
              src={loginImage4}
              alt="Image 4"
              width={'38%'}
              style={{ borderRadius: '12px', objectFit: 'cover' }}
            />
            <img
              src={loginImage5}
              alt="Image 5"
              width={'58%'}
              style={{ borderRadius: '12px', objectFit: 'cover' }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
