import { Typography } from 'design-system';
import { PropsWithChildren } from 'react';

interface PropTypes extends PropsWithChildren {}

/**
 * A shared UI component for rendering a title on a page with a form.
 */
export const PageFormTitle: React.FC<PropTypes> = (props: PropTypes) => {
  const { children } = props;

  return (
    <Typography
      variant="H3"
      typography={{
        xs: 'H4',
        sm: 'H3',
      }}
    >
      {children}
    </Typography>
  );
};
