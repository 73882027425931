import { isPast } from 'date-fns';
import { useCallback, useEffect, useRef } from 'react';

import { useRouting } from './useRouting';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';

export const useExpiredReservationRedirect = ({
  skip,
  reservationExpiryDate,
}: {
  skip?: boolean;
  reservationExpiryDate?: string | null;
}) => {
  const { navigate } = useRouting();
  const timeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null);

  const handleRedirect = useCallback(() => {
    navigate(CheckoutRoutePath.RESERVATION_EXPIRED.value, { preserveExisting: true });
  }, [navigate]);

  // redirect to reservation expired page if reservation has expired
  useEffect(() => {
    if (skip || !reservationExpiryDate) {
      return;
    }

    const expiresAt = new Date(reservationExpiryDate);

    if (isPast(expiresAt)) {
      handleRedirect();
    } else if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {
        handleRedirect();
      }, expiresAt.getTime() - Date.now());
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [navigate, reservationExpiryDate]);
};
