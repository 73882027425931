import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { Button, Stack, TickIcon, Typography } from 'design-system';
import { useNavigate } from 'react-router-dom';

export const BalancePaymentSuccess = () => {
  const navigate = useNavigate();
  const { tripId } = useRequiredParams(['tripId']);

  return (
    <Stack gap={4}>
      <Stack alignItems={'center'} gap={2}>
        <TickIcon
          sx={{
            width: {
              xs: '70px',
              sm: '98px',
            },
            height: {
              xs: '70px',
              sm: '98px',
            },
          }}
        />
        <Stack gap={1}>
          <Typography
            data-testid="payment-complete-message"
            textAlign="center"
            variant="pageHeader"
            typography={{
              xs: 'H5',
              sm: 'H4',
            }}
          >
            Payment complete
          </Typography>
          <Typography
            textAlign="center"
            variant="bodyPara"
            typography={{
              xs: 'Body S',
              sm: 'Body M',
            }}
            maxWidth={729}
          >
            We’ll email your payment confirmation shortly. Please allow a few minutes for
            your payment summary to update.
          </Typography>
        </Stack>
        <Button
          variant="contained"
          fullWidth={false}
          sx={{ width: 'fit-content' }}
          onClick={() => navigate(CheckoutRoutePath.BOOKING_DETAILS.generatePath(tripId))}
        >
          Back to trip page
        </Button>
      </Stack>
    </Stack>
  );
};
