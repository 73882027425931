import React from 'react';
import { Basket, CustomerCurrency, PaymentOptionType } from '@flashpack/graphql';
import {
  Box,
  composeValidators,
  FormDateInput,
  RadioAccordionGroupOption,
  Stack,
  Typography,
  Validator,
} from 'design-system';
import { formatCurrency } from '../../shared/utils/currencyUtils';
import {
  getPaymentPlanLatestStartDate,
  InstalmentsCalculator,
} from '@src/shared/instalments-calculator/InstalmentsCalculator';
import { FormApi } from 'final-form';
import { BookDepartureFormValues } from '../BookDeparturePage';
import { format, isAfter, isValid, parseISO } from 'date-fns';

interface PaymentPlanOptionProps {
  basket: Basket;
  departureDate: string;
  form: FormApi<BookDepartureFormValues>;
  paymentPlanType: PaymentOptionType.ThreeMonthsPlan | PaymentOptionType.SixMonthsPlan;
  paymentPlanStartDate: string | null;
}

export const PaymentPlanOptionContent: React.FC<PaymentPlanOptionProps> = (props) => {
  const { basket, paymentPlanStartDate, paymentPlanType } = props;

  const currency = basket.currency as CustomerCurrency;

  const latestPaymentPlanStartDate = getPaymentPlanLatestStartDate({
    departureDate: props.departureDate,
    paymentOption: props.paymentPlanType,
  });

  const numberOfInstalments =
    paymentPlanType === PaymentOptionType.ThreeMonthsPlan ? 3 : 6;

  const instalmentCalculator = new InstalmentsCalculator({
    totalCost: basket.tripTotal,
    currency,
    paymentOption: props.paymentPlanType,
    departureDate: props.departureDate,
    depositAmount: basket.tourDeposit,
    paymentPlanStartDate: paymentPlanStartDate ?? latestPaymentPlanStartDate,
  });
  const { instalments, error } = instalmentCalculator.calculate();

  const instalmentsWithDeposit = [
    {
      date: paymentPlanStartDate,
      amount: basket.tourDeposit,
      dateYyyyMmDd: paymentPlanStartDate,
    },
    ...instalments,
  ];

  return (
    <Stack gap={4}>
      <Typography variant="Body S">
        Secure your place on the trip with a{' '}
        {formatCurrency(basket.tourDeposit, currency)} deposit today and spread the
        remaining payments over {numberOfInstalments} months.
      </Typography>
      <FormDateInput
        id="payment-plan-start-date"
        name="selectedPaymentOptionStartDate"
        data-testid="payment-plan-start-date"
        validate={composeValidators(
          Validator.dateNotInThePast,
          (dateString: string | null) => {
            if (!dateString || !isValid(parseISO(dateString))) {
              return 'The date is not valid';
            }
            const date = parseISO(dateString);

            if (isAfter(date, parseISO(latestPaymentPlanStartDate))) {
              return `Date is too close to your trip. It must be before ${format(
                parseISO(latestPaymentPlanStartDate),
                'd MMM yy',
              )}.`;
            }
            return undefined;
          },
        )}
        label="Choose your first payment date"
        value={paymentPlanStartDate}
      />
      <Box
        p={2}
        sx={{
          border: '1px solid',
          borderColor: 'grey.300',
          borderRadius: '16px',
          width: '250px',
        }}
      >
        {instalments.length > 0 && (
          <Typography variant="Body S">Your plan at a glance</Typography>
        )}
        {instalmentsWithDeposit.map((instalment, instalmentIdx) => (
          <Stack
            key={instalment.date || instalmentIdx}
            flexDirection={'row'}
            alignItems={'center'}
            sx={{
              justifyContent: 'space-between',
              '&:not(:last-child)': {
                borderBottom: '1px solid',
                borderColor: 'grey.300',
              },
            }}
          >
            <Typography variant="Body S">
              {instalmentIdx === 0 ? 'Today ' : instalment.date}
            </Typography>
            <Typography variant="Body M bold">
              {formatCurrency(instalment.amount, currency)}
            </Typography>
          </Stack>
        ))}

        {error && (
          <Typography variant="Body S" color="error" sx={{ pt: 2 }}>
            {error}
          </Typography>
        )}
      </Box>
    </Stack>
  );
};

export const createPaymentPlanOptionContent: (
  params: PaymentPlanOptionProps,
) => RadioAccordionGroupOption = (params) => {
  return {
    value: params.paymentPlanType,
    label: `Set up ${
      params.paymentPlanType === PaymentOptionType.ThreeMonthsPlan ? '3' : '6'
    } month payment plan`,
    content: <PaymentPlanOptionContent {...params} />,
  };
};
