import { Box } from '@mui/system';
import React, { ReactNode } from 'react';
import Header from '../Header/Header';
import { Footer } from '../Footer/Footer';

interface LayoutProps {
  children: ReactNode;
  HeroComponent?: ReactNode;
}

/**
 * A responsive layout padding that can be reused across global UI components
 * to make an effect of horizontal alignment.
 *
 * E.g.:
 * [   ] Page header  [   ]
 * [   ] Page content [   ]
 * [   ] Page footer  [   ]
 *   ^__________________^_________ shared padding
 */
export const pageHorizontalPadding = {
  xs: 2,
  sm: 3,
  md: 3,
  lg: 3,
} as const;

export const pageMaxWidthPx = 1140;

const Layout: React.FC<LayoutProps> = ({ children, HeroComponent }) => {
  return (
    <>
      <Header />
      {HeroComponent}
      <Box
        sx={{
          marginLeft: 'auto',
          marginRight: 'auto',
          px: pageHorizontalPadding,
          paddingTop: '40px',
          paddingBottom: '80px', // change it when footer is added
          maxWidth: 1140,
        }}
      >
        {children}
      </Box>
      <Footer />
    </>
  );
};

export default Layout;
