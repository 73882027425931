import React from 'react';
import { Card, CardContent, useMediaQuery } from '@mui/material';
import { Box, CircleArrowRight, Pill, styled, Typography, useTheme } from 'design-system';
import defaultImage from '@src/shared/book-departure-header/DepartureHeaderImage.png';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

interface TripBulletinProps {
  tripName: string;
  startDate: Date;
  endDate: Date;
  remainingBalance: number;
  image: string;
  link: string;
}

export const TripBulletin: React.FC<TripBulletinProps> = ({
  tripName,
  startDate,
  endDate,
  remainingBalance,
  image,
  link,
}) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const formatDate = (date: Date) => format(date, 'EEE MMM dd yyyy');
  const isPaid = remainingBalance === 0;
  const navigate = useNavigate();
  return (
    <StyledCard backgroundImage={image} onClick={() => navigate(link)}>
      <Overlay />
      <StyledCardContent>
        <Header>
          <Typography variant={isMdUp ? 'H3' : 'H5'}>{tripName}</Typography>
        </Header>
        {!isMdUp && <Pill variant="warning">Partially paid</Pill>}
        <DateRange>
          {isMdUp ? (
            <>
              <Pill variant="standard" textVariant={isMdUp ? 'Body M' : 'Body S'}>
                {formatDate(startDate)}
              </Pill>

              <Typography variant="Body L">-</Typography>
              <Pill variant="standard" textVariant={isMdUp ? 'Body M' : 'Body S'}>
                {formatDate(endDate)}
              </Pill>
            </>
          ) : (
            <Typography variant="Body S" color="white">
              {formatDate(startDate)} - {formatDate(endDate)}
            </Typography>
          )}
        </DateRange>
      </StyledCardContent>
      <RightHandSide>
        {!isPaid && isMdUp && <Pill variant="warning">Partially paid</Pill>}
        <CircleArrowRight
          sx={{
            width: '42px',
            height: '42px',
            fill: 'none',
            color: 'white',
            stroke: 'white',
            '& path, & circle': {
              stroke: 'white',
            },
          }}
        />
      </RightHandSide>
    </StyledCard>
  );
};

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'backgroundImage',
})<{ backgroundImage: string }>(({ backgroundImage }) => ({
  backgroundImage: `url(${backgroundImage || defaultImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: 'white',
  position: 'relative',
  overflow: 'hidden',
  height: 170,
  display: 'flex',
  justifyContent: 'space-between',
  padding: 24,
  borderRadius: 12,
  cursor: 'pointer',
}));

const Overlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%)',
});

const StyledCardContent = styled(CardContent)({
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 8,
  height: '100%',
  padding: 0,
});

const RightHandSide = styled(Box)({
  zIndex: 2,
  marginTop: 'auto',
  marginBottom: 'auto',
  display: 'flex',
  alignItems: 'center',
  gap: 8,
});

const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
});

const DateRange = styled(Box)({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
});
