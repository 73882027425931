import { Form, Field } from 'react-final-form';
import { useMutation, useQuery } from '@apollo/client';
import {
  CurrentCustomerCommunicationPreferencesDocument,
  UpdateCustomerCommunicationPreferencesDocument,
} from '@flashpack/graphql';
import {
  Typography,
  Stack,
  FormControlLabel,
  CheckboxFormField,
  GenericError,
  Skeleton,
} from 'design-system';
import { useToast } from '@src/shared/toast/useToast';

interface FormValues {
  contactPhone: boolean;
  contactSms: boolean;
  contactEmail: boolean;
}

export const CommunicationPreferencesSection = () => {
  const {
    data,
    loading,
    error: queryError,
  } = useQuery(CurrentCustomerCommunicationPreferencesDocument);
  const [updatePreferences, { loading: updateLoading, error: updateError }] = useMutation(
    UpdateCustomerCommunicationPreferencesDocument,
  );
  const { success, error: errorToast } = useToast();

  const onSubmit = async (values: FormValues) => {
    try {
      await updatePreferences({
        variables: {
          input: {
            contactPhone: !!values.contactPhone,
            contactSms: !!values.contactSms,
            contactEmail: !!values.contactEmail,
          },
        },
      });
      success('Communication preferences updated');
    } catch (error) {
      errorToast('Error updating preferences');
    }
  };

  if (loading) {
    return <Skeleton variant="rounded" width={210} height={118} />;
  }

  if (queryError || updateError) {
    return (
      <GenericError
        error={
          'Something went wrong when updating your communication preferences. Please try again later.'
        }
      />
    );
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={data?.currentCustomerCommunicationPreferences}
      render={({ handleSubmit }) => (
        <form
          onChange={(e) => {
            void handleSubmit();
            e?.preventDefault();
          }}
        >
          <Stack gap={2} paddingTop={2}>
            <Typography variant="Body S">
              Flash Pack would love to get in touch from time to time with exclusive
              offers and solo travel inspiration. If you&apos;re happy for us to do so,
              please tick your preferred form of contact below.
            </Typography>
            <Stack direction="row" gap={2}>
              <FormControlLabel
                label="Phone"
                control={
                  <Field
                    name="contactPhone"
                    type="checkbox"
                    component={CheckboxFormField}
                    disabled={updateLoading}
                  />
                }
              />
              <FormControlLabel
                label="Text"
                control={
                  <Field
                    name="contactSms"
                    type="checkbox"
                    component={CheckboxFormField}
                    disabled={updateLoading}
                  />
                }
              />
              <FormControlLabel
                label="Email"
                control={
                  <Field
                    name="contactEmail"
                    type="checkbox"
                    component={CheckboxFormField}
                    disabled={updateLoading}
                  />
                }
              />
            </Stack>
          </Stack>
        </form>
      )}
    />
  );
};
