import {
  Checkbox,
  FormControlLabel,
  PhoneWithNotificationsIcon,
  Stack,
  Typography,
} from 'design-system';

interface PropTypes {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
}

export const JoinWhatsApp = (props: PropTypes) => {
  const { checked, onChange, name = 'joinWhatsAppGroup' } = props;
  return (
    <Stack
      gap={2}
      sx={{
        border: '1px solid',
        borderColor: 'brand.jungle',
        padding: '20px',
        borderRadius: '12px',
      }}
    >
      <Typography variant="H5">Join your trip’s WhatsApp group</Typography>

      <Stack gap={3} direction="row">
        <Typography variant="Additional" sx={{ whiteSpace: 'pre-line', maxWidth: 376 }}>
          We set up WhatsApp groups before your trip to help everybody get to know each
          other. We’ll introduce the pack leader, who will use it to communicate updates
          with your group whilst on tour.
          {'\n'}
          {'\n'}
          (Please be aware your number will be visible to all other members of your trip)
        </Typography>

        <PhoneWithNotificationsIcon sx={{ width: 137, height: 92 }} />
      </Stack>

      <FormControlLabel
        label={<Typography variant="Body S">Yes please!</Typography>}
        checked={checked}
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            data-testid="joinWhatsAppGroup"
            name={name}
          />
        }
      />
    </Stack>
  );
};
