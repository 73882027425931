import { MyDetailsSection } from '@src/my-account/account-details/MyDetailsSection';
import { Grid, Typography, useTheme } from 'design-system';
import { UpdateCardDetailsDropIn } from '../UpdateCardDetailsDropIn';
import { CommunicationPreferencesSection } from './CommunicationPreferencesSection';
import { Theme } from '@mui/material';

export const MyAccountPage = () => {
  const theme = useTheme();
  const commonGridItemProps = getCommonGridItemProps(theme);
  const commonTypographyProps = getCommonTypographyProps();

  return (
    <Grid container marginTop={2} direction="row" spacing={{ md: 3 }}>
      <Grid {...commonGridItemProps} borderTop={0}>
        <MyDetailsSection />
      </Grid>
      <Grid {...commonGridItemProps}>
        <Typography {...commonTypographyProps}>Card details</Typography>
        <UpdateCardDetailsDropIn />
      </Grid>
      <Grid {...commonGridItemProps}>
        <Typography {...commonTypographyProps}>Keeping in touch</Typography>
        <CommunicationPreferencesSection />
      </Grid>
    </Grid>
  );
};

const getCommonGridItemProps = (theme: Theme) => ({
  item: true,
  xs: 12,
  md: 6,
  lg: 4,
  borderTop: {
    xs: `1px solid ${theme.palette.grey[300]}`,
    md: 0,
  },
  marginTop: {
    xs: 2,
    md: 0,
  },
});

const getCommonTypographyProps = () => ({
  variant: 'subHeader' as const,
  marginTop: {
    xs: 2,
    md: 0,
  },
});
