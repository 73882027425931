import { MyAccountTabs } from '@src/my-account/MyAccountTabs';
import Layout from '@src/shared/Layout/Layout';
import { Outlet } from 'react-router-dom';

export const MyAccountParentPage = () => {
  return (
    <>
      <Layout>
        <MyAccountTabs />
        <Outlet />
      </Layout>
    </>
  );
};
