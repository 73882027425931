import { Skeleton, Stack } from 'design-system';
import { ResponsiveSideBySide } from '../responsive-side-by-side/ResponsiveSideBySide';

/**
 * A shared UI component for rendering a bunch of skeleton loaders for a page with a form.
 * Makes page loading feel faster.
 *
 * Should be used instead of the form when the data is loading.
 */
export const PageFormLoader: React.FC = () => {
  return (
    <ResponsiveSideBySide
      mainSection={
        <Stack gap={5}>
          <Skeleton
            variant="rounded"
            width="100%"
            height={94}
            sx={{ borderRadius: 2 }}
            animation={'wave'}
          />

          <Stack gap={3}>
            <Skeleton variant="rectangular" height={40} width={280} animation={'pulse'} />
            <Skeleton variant="rectangular" height={40} width={354} animation={'pulse'} />
            <Skeleton variant="rectangular" height={40} width={354} animation={'pulse'} />
            <Skeleton variant="rectangular" height={40} width={354} animation={'pulse'} />
            <Skeleton variant="rectangular" height={40} width={354} animation={'pulse'} />
          </Stack>

          <Stack gap={3}>
            <Skeleton variant="rectangular" height={40} width={280} animation={'pulse'} />
            <Skeleton variant="rectangular" height={40} width={354} animation={'pulse'} />
            <Skeleton variant="rectangular" height={40} width={354} animation={'pulse'} />
            <Skeleton variant="rectangular" height={40} width={354} animation={'pulse'} />
            <Skeleton variant="rectangular" height={40} width={354} animation={'pulse'} />
          </Stack>
        </Stack>
      }
      additionalSection={
        <Stack gap={3}>
          <Skeleton
            variant="rounded"
            height={547}
            sx={{ borderRadius: 6 }}
            animation={'pulse'}
          />
          <Skeleton
            variant="rounded"
            height={156}
            sx={{ borderRadius: 6 }}
            animation={'pulse'}
          />
        </Stack>
      }
    />
  );
};
