import { CustomerCurrency } from '@flashpack/graphql';

export const formatCurrency = (amount: string | number, currency: CustomerCurrency) => {
  const amountNumber = Number(amount);

  const userLocale = window.navigator.language || 'en-US';
  const isDecimalPartPresent = amountNumber % 1 !== 0;

  return new Intl.NumberFormat(userLocale, {
    style: 'currency',
    currency,
    minimumFractionDigits: isDecimalPartPresent ? 2 : 0,
  }).format(amountNumber);
};
