import { AuthenticationContext, useFirebaseAuth } from '@src/auth/useAuthentication';
import { FC, ReactNode } from 'react';

export const AuthenticationProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const { authenticated, loadingAuthentication } = useFirebaseAuth();

  return (
    <AuthenticationContext.Provider value={{ authenticated, loadingAuthentication }}>
      {children}
    </AuthenticationContext.Provider>
  );
};
