import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';

import { BookingTermsAndConditions } from './BookingTermsAndConditions';

type PropTypes = FieldRenderProps<boolean> & {
  termsAndConditionsHTML: string;
};

export const BookingTermsAndConditionsFormField: React.FC<PropTypes> = ({
  input,
  meta,
  termsAndConditionsHTML,
}) => {
  const { touched } = meta;

  return (
    <BookingTermsAndConditions
      {...input}
      // RFF has "any" type for error;
      // we need to specify type explicitly to suppress eslint error
      error={meta.error as string}
      touched={touched}
      checked={Boolean(input.checked)}
      termsAndConditionsHTML={termsAndConditionsHTML}
    />
  );
};
