import { ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client';
import fetch from 'cross-fetch';
import { introspectionResult } from '@flashpack/graphql';
import { onError } from '@apollo/client/link/error';
import { captureException } from '@sentry/react';
import { setContext } from '@apollo/client/link/context';
import { getAuth } from 'firebase/auth';
import { relayStylePagination } from '@apollo/client/utilities';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';
import * as FirebaseAuth from 'firebase/auth';
import * as Sentry from '@sentry/browser';

const getServerUri = () => {
  return import.meta.env.VITE_APOLLO_URI;
};

const apolloUri = getServerUri();

const authContext = setContext(async () => {
  const token = (await getAuth().currentUser?.getIdToken()) || '';
  return { headers: { Authorization: `Bearer ${token}`, consumer: 'CHECKOUT' } };
});

const handleUserAuthError = async () => {
  await FirebaseAuth.signOut(FirebaseAuth.getAuth());
  const currentUrl = new URL(window.location.href);
  const queryParams = currentUrl.search;

  const newBaseUrl = CheckoutRoutePath.LOGIN.value;
  const newUrl = `${newBaseUrl}${queryParams}`;
  window.location.replace(newUrl);
};

const errorLink = onError((e) => {
  if (e.graphQLErrors) {
    if (e.graphQLErrors?.find((e) => e.extensions.code === 'AUTHORIZATION_ERROR')) {
      Sentry.captureMessage(
        'User has received an authorization error and is going to be logged out and redirected to login.',
        {
          level: 'warning',
          extra: {
            errorResponse: e,
          },
          tags: {
            operationName: e.operation.operationName,
          },
        },
      );

      void handleUserAuthError();
    }
  }

  if (e.networkError) {
    captureException(e.networkError);
  }
});

const getHttpLink = () => {
  if (!apolloUri) {
    throw new Error('The Apollo server URI has not been defined');
  }
  return createHttpLink({ uri: apolloUri, fetch, credentials: 'include' });
};

export const apolloClient = new ApolloClient({
  credentials: 'include',
  link: from([authContext, errorLink, getHttpLink()]),
  cache: new InMemoryCache({
    ...introspectionResult,
    typePolicies: {
      Query: {
        fields: {
          userActivities: relayStylePagination(),
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    query: {
      fetchPolicy: 'network-only',
    },
  },
});

export const openApolloSandbox = () => {
  void getAuth()
    .currentUser?.getIdToken()
    .then((token) => {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      window.open(`${apolloUri}?headers=${JSON.stringify(headers)}`, '_blank');
    });
};
