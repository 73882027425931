import React from 'react';
import { Basket, CustomerCurrency, PaymentOptionType } from '@flashpack/graphql';
import { RadioAccordionGroupOption, Typography } from 'design-system';
import { formatCurrency } from '@src/shared/utils/currencyUtils';

export const PayFullAmountOptionContent: React.FC = () => {
  return null;
};

export const createPayFullAmountRadioGroupOption: (params: {
  basket: Basket;
}) => RadioAccordionGroupOption = (params) => {
  const { basket } = params;
  return {
    value: PaymentOptionType.FullBalance,
    label: 'Pay full balance now',
    endAdornment: (
      <Typography variant="H6">
        {formatCurrency(basket.tripTotal, basket.currency as CustomerCurrency)}
      </Typography>
    ),
    content: null,
  };
};
