import { useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import {
  Basket,
  CustomerCurrency,
  TripExtrasDocument,
  UpdateBasketDocument,
} from '@flashpack/graphql';
import { useMutation, useQuery } from '@apollo/client';
import { formatCurrency } from '../utils/currencyUtils';
import { useRouting } from '@src/shared/hooks/useRouting';
import { Box, CheckboxFormField, FormControlLabel } from 'design-system';
import { Stack, Typography } from '@mui/material';

type FormData = { extras: Array<{ id: number; selected: boolean }> };

type PropTypes = {
  basket: Basket;
};

export const Extras = ({ basket }: PropTypes) => {
  const { queryParams } = useRouting<{ departureCode: string; currencyCode: string }>();
  const { departureCode, currencyCode } = queryParams;
  const [updateBasket] = useMutation(UpdateBasketDocument);

  const { extras: selectedBasketExtras } = basket;
  const customerCurrency = currencyCode.toLowerCase() as CustomerCurrency;

  const { data: extrasQueryResponse, loading: loadingExtras } = useQuery(
    TripExtrasDocument,
    {
      variables: { input: { departureCode, currency: customerCurrency } },
    },
  );

  const extrasOptions = useMemo(() => {
    if (!extrasQueryResponse) {
      return [];
    }
    const options = extrasQueryResponse.tripExtras.extras.map((extra) => ({
      id: extra.id,
      label: extra.name,
      description: extra.description,
      priceLabel: formatCurrency(extra.price, customerCurrency),
    }));

    return options;
  }, [extrasQueryResponse, customerCurrency]);

  const initialFormValues = useMemo(() => {
    return {
      extras: extrasOptions.map((e) => {
        return {
          id: e.id,
          selected: !!selectedBasketExtras?.find(
            (selectedExtra) => selectedExtra.id === e.id,
          ),
        };
      }),
    };
  }, [extrasOptions, selectedBasketExtras]);

  const onSubmit = (formData: FormData) => {
    const updateExtrasInput = formData.extras
      .filter((e) => e.selected)
      .map((extra) => ({
        id: extra.id,
      }));

    void updateBasket({
      variables: {
        input: {
          departureCode,
          id: basket.id,
          extras: updateExtrasInput,
        },
      },
    });
  };

  if (loadingExtras) {
    return null;
  }
  if (!extrasOptions.length) {
    return null;
  }

  return (
    <Form<FormData>
      keepDirtyOnReinitialize
      initialValues={initialFormValues}
      onSubmit={onSubmit}
      render={({ form }) => {
        return (
          <form>
            <Stack direction="column" spacing={2}>
              <Typography variant="H5">Extras</Typography>
              <Typography variant="Body S">
                Select any extras you would like to add to your trip
              </Typography>
              <Box>
                {extrasOptions.map((option, index) => (
                  <Stack
                    key={option.id}
                    direction={'row'}
                    justifyContent="space-between"
                    alignItems="flex-start"
                    mb={index === extrasOptions.length - 1 ? 0 : 1}
                  >
                    <FormControlLabel
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                      }}
                      label={
                        <Stack mt="5px">
                          <Typography variant="Body M">{option.label}</Typography>
                          {option.description && (
                            <Typography variant="Additional">
                              {option.description}
                            </Typography>
                          )}
                        </Stack>
                      }
                      control={
                        <Field<boolean>
                          name={`extras.${index}.selected`}
                          data-testid={`select-extras-${index}`}
                          type="checkbox"
                          defaultValue={false}
                          component={CheckboxFormField}
                          onChange={() => {
                            void form.submit();
                          }}
                        />
                      }
                    />
                    <Typography mt="5px" variant="Body M bold">
                      {option.priceLabel}
                    </Typography>
                  </Stack>
                ))}
              </Box>
            </Stack>
          </form>
        );
      }}
    />
  );
};
