import { FC } from 'react';
import { useQuery } from '@apollo/client';
import {
  BookingDetailsDocument,
  CustomerCurrency,
  OrderDetailsQueryDocument,
} from '@flashpack/graphql';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';
import { OrderDetails } from '@src/shared/order-details/OrderDetails';
import { ResponsiveSideBySide } from '@src/shared/responsive-side-by-side/ResponsiveSideBySide';
import {
  BreadCrumbs,
  Divider,
  GenericError,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from 'design-system';
import { Link, useNavigate } from 'react-router-dom';
import { PaymentStatus } from './PaymentStatus';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { TripImages } from './TripImages';

export const BookedTripPage: FC = () => {
  const { tripId } = useRequiredParams(['tripId']);

  const { data, loading, error } = useQuery(BookingDetailsDocument, {
    variables: {
      input: {
        id: parseInt(tripId),
      },
    },
  });
  const navigate = useNavigate();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const trip = data?.bookingDetails;

  const currencyCode = trip?.paymentInformation.currencyCode;

  const departureCode = trip?.trip.departureCode;

  const { data: orderDetailsData, loading: orderDetailsLoading } = useQuery(
    OrderDetailsQueryDocument,
    {
      skip: !departureCode || !currencyCode,
      variables: {
        departureCode: departureCode as string,
        currency: currencyCode?.toLowerCase() as CustomerCurrency,
      },
    },
  );

  if (!departureCode || !trip || !currencyCode) {
    navigate(CheckoutRoutePath.MY_TRIPS.value);
    return null;
  }

  if (loading) {
    return (
      <Skeleton variant="rectangular" height={140} width={'full'} animation={'pulse'} />
    );
  }

  if (error) {
    return (
      <GenericError
        error={'There was an error loading your trips. Please try again or contact us.'}
      />
    );
  }

  return (
    <Stack sx={{ paddingTop: 1 }}>
      <BreadCrumbs separator="›" sx={{ paddingBottom: 5 }}>
        <Typography variant="Body S">
          <Link
            to={CheckoutRoutePath.MY_TRIPS.value}
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            Your Trips
          </Link>
        </Typography>
        <Typography variant="Body S" color={theme.palette.common.black}>
          {data.bookingDetails.trip.country}
        </Typography>
      </BreadCrumbs>
      <Typography sx={{ paddingBottom: 3 }} variant="h1">
        {trip?.trip.name || trip?.trip.departureCode}
      </Typography>
      <ResponsiveSideBySide
        mainSection={
          <Stack gap={3}>
            <TripImages images={trip.trip.tripImages} />
            <Divider />
            <PaymentStatus
              currency={currencyCode as CustomerCurrency}
              totalAmount={trip.paymentInformation.totalAmount}
              remainingAmount={trip.paymentInformation.remainingAmount}
              paymentType={trip.paymentInformation.paymentOption}
              previousPayments={trip.paymentInformation.paymentsMade}
              departureDate={trip.trip.dateFrom}
              paymentPlanStartDate={trip.paymentInformation.paymentPlanStartDate || null}
            />
          </Stack>
        }
        additionalSection={
          <Stack gap={3}>
            {(isMediumScreen || isLargeScreen) && (
              <OrderDetails
                loading={orderDetailsLoading}
                orderDetails={orderDetailsData?.orderDetails}
              />
            )}
          </Stack>
        }
      ></ResponsiveSideBySide>
    </Stack>
  );
};
