import React from 'react';
import { Box, Button, Stack, Typography } from 'design-system';

import Layout from '@src/shared/Layout/Layout';
import { Contacts } from '@src/shared/contacts/Contacts';
import { HeroHeader } from '@src/shared/hero-header/HeroHeader';

export const SoldOutPage: React.FC = () => {
  return (
    <Layout HeroComponent={<HeroHeader text="Trip sold out!" />}>
      <Stack gap={2} alignItems={'center'}>
        <Typography
          variant="H5"
          typography={{
            xs: 'H6',
            sm: 'H5',
          }}
          maxWidth={600}
          textAlign={'center'}
        >
          We’re sorry, the last space on this trip has now been sold.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          href="https://www.flashpack.com/adventure-trips/"
        >
          See other trips
        </Button>

        <Box mt={7}>
          <Contacts />
        </Box>
      </Stack>
    </Layout>
  );
};
