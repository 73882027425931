import { Tab, Tabs } from '@mui/material';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';
import { hasHandle, isString } from '@src/shared/routing/hasHandle';
import { useMatches } from 'react-router-dom';
import LinkBehavior from '@src/shared/routing/LinkBehaviour';
import { Button, Stack } from 'design-system';
import { useFirebaseAuth } from '@src/auth/useAuthentication';

export const MY_ACCOUNT_TAB_HANDLE = 'myAccountTab';

export enum MyAccountTab {
  MY_ACCOUNT_DETAILS = 'Your account',
  MY_TRIPS = 'My trips',
}

export const MyAccountTabs = () => {
  const matches = useMatches();
  const myAccountTabMatch = matches.find(hasHandle(MY_ACCOUNT_TAB_HANDLE, isString));
  const myAccountTab = myAccountTabMatch?.handle.myAccountTab;
  const { signOut } = useFirebaseAuth();

  return (
    <Stack direction="row" alignItems="center" sx={{ mb: 7 }}>
      <Tabs sx={{ flexGrow: 1, marginTop: 2, marginBottom: 2 }} value={myAccountTab}>
        <Tab
          label={MyAccountTab.MY_TRIPS}
          value={MyAccountTab.MY_TRIPS}
          href={CheckoutRoutePath.MY_TRIPS.value}
          LinkComponent={LinkBehavior}
          data-testid="my-trips"
        />
        <Tab
          label={MyAccountTab.MY_ACCOUNT_DETAILS}
          value={MyAccountTab.MY_ACCOUNT_DETAILS}
          href={CheckoutRoutePath.MY_ACCOUNT_DETAILS.value}
          LinkComponent={LinkBehavior}
          data-testid="my-account-details"
        />
      </Tabs>
      <Button
        variant="outlined"
        sx={{ ml: 7, height: '51px' }}
        onClick={() => void signOut()}
      >
        Log out
      </Button>
    </Stack>
  );
};
