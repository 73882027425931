import { useTheme, useMediaQuery, Typography } from 'design-system';

import React from 'react';

interface StepperPillProps {
  currentStep: number;
  totalSteps: number;
}

const StepperPill: React.FC<StepperPillProps> = ({ currentStep, totalSteps }) => {
  const theme = useTheme();

  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Typography
      variant={isLargeScreen || isMediumScreen ? 'Body M' : 'Body S'}
      sx={{
        display: 'flex',
        padding: {
          xs: '8px 12px',
          sm: '8px 12px',
          md: '12px 16px',
        },
        alignItems: 'flex-start',
        gap: '4px',
        backgroundColor: theme.palette.brand.jungle,
        color: theme.palette.common.white,
        borderRadius: 6,
        width: 'fit-content',
      }}
    >
      <b>Step {currentStep}</b> of {totalSteps}
    </Typography>
  );
};

export default StepperPill;
