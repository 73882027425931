import { FC } from 'react';
import { Box, styled } from 'design-system';

interface TripImagesProps {
  images?: string[] | null;
}

const ImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const StyledImage = styled('img')({
  height: '148px',
  objectFit: 'cover',
  borderRadius: '8px',
});

export const TripImages: FC<TripImagesProps> = ({ images }) => {
  if (!images || images.length !== 2) {
    return null;
  }
  return (
    <ImageContainer>
      <StyledImage src={images[0]} alt={`Trip image 1`} width={'40%'} />
      <StyledImage src={images[1]} alt={`Trip image 2`} width={'60%'} />
    </ImageContainer>
  );
};
