import React from 'react';
import { Link, Stack, Typography } from 'design-system';
import { SectionHeader } from './Footer';

export type FooterListItem = {
  title: string;
  linkHref: string;
};

export type FooterSectionProps = {
  sectionTitle: string;
  listItems?: Array<FooterListItem>;
  postListContent?: React.ReactNode;
};

export const FooterSection: React.FC<FooterSectionProps> = ({
  sectionTitle,
  listItems,
  postListContent,
}) => {
  return (
    <Stack gap={3}>
      <SectionHeader>{sectionTitle}</SectionHeader>
      {listItems && listItems.length > 0 && (
        <Stack gap={2}>
          {listItems.map((item) => (
            <Link
              href={item.linkHref}
              key={item.title + item.linkHref}
              sx={{ textDecoration: 'none' }}
            >
              <Typography variant="captionPara" color={'principal.black'}>
                {item.title}
              </Typography>
            </Link>
          ))}
        </Stack>
      )}
      {postListContent}
    </Stack>
  );
};
