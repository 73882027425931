import React from 'react';
import { CustomerCurrency, PaymentOptionType } from '@flashpack/graphql';
import { Stack, Typography, Button, Box, Pill, useTheme, Divider } from 'design-system';
import { formatCurrency } from '@src/shared/utils/currencyUtils';
import { InstalmentsCalculator } from '@src/shared/instalments-calculator/InstalmentsCalculator';
import { formatDateToOrdinalMonthYear } from '@src/shared/dateUtils';
import { format, subDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';
import { useRequiredParams } from '@src/shared/useRequiredParams';

interface Payment {
  date: string;
  amount: number;
}

interface PaymentStatusProps {
  currency: CustomerCurrency;
  totalAmount: number;
  remainingAmount: number;
  paymentType: PaymentOptionType;
  previousPayments: Payment[];
  departureDate: string;
  paymentPlanStartDate: string | null;
}

export const PaymentStatus: React.FC<PaymentStatusProps> = ({
  currency,
  totalAmount,
  remainingAmount,
  paymentType,
  previousPayments,
  departureDate,
  paymentPlanStartDate,
}) => {
  const { tripId } = useRequiredParams(['tripId']);

  const theme = useTheme();
  const navigate = useNavigate();
  const isFullyPaid = remainingAmount <= 0;

  const isPaymentPlan =
    paymentType === PaymentOptionType.SixMonthsPlan ||
    paymentType === PaymentOptionType.ThreeMonthsPlan;

  const instalmentCalculator = new InstalmentsCalculator({
    totalCost: totalAmount,
    currency,
    paymentOption: paymentType,
    departureDate,
    depositAmount: totalAmount - remainingAmount,
    paymentPlanStartDate: paymentPlanStartDate || undefined,
  });

  const { instalments } = instalmentCalculator.calculate();

  // TODO: get this from the server
  const BNPLPaymentDate = format(subDays(new Date(departureDate), 90), 'dd MMM yyyy');

  return (
    <Stack gap={2}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="H5">Your payment Summary</Typography>
        <Pill variant={isFullyPaid ? 'success' : 'warning'}>
          {isFullyPaid ? 'Paid' : 'Partially paid'}
        </Pill>
      </Box>

      <Stack
        sx={{
          backgroundColor: isFullyPaid
            ? theme.palette.system.green10
            : theme.palette.system.blue10,
          padding: 4,
          borderRadius: 2,
        }}
      >
        <Stack gap={1}>
          {previousPayments.map((payment, index) => (
            <Stack key={index} direction="row" justifyContent="space-between">
              <Typography variant="Body M">
                Paid on {formatDateToOrdinalMonthYear(payment.date)}
              </Typography>
              <Typography variant="Body M bold">
                {formatCurrency(payment.amount, currency)}
              </Typography>
            </Stack>
          ))}
        </Stack>

        <Divider />

        <Stack direction="row" justifyContent="space-between">
          <Typography variant="H4">
            {isFullyPaid ? 'Total paid' : 'Left to pay'}
          </Typography>
          <Typography variant="H4">
            {formatCurrency(isFullyPaid ? totalAmount : remainingAmount, currency)}
          </Typography>
        </Stack>
        {remainingAmount > 0 && paymentType === PaymentOptionType.BookNowPayLater && (
          <Typography variant="Body S">{`Payment will be taken on ${BNPLPaymentDate}`}</Typography>
        )}
        {remainingAmount > 0 && isPaymentPlan && (
          <>
            <Typography variant="Body M">Upcoming payments</Typography>
            <Box
              sx={{
                border: `1px solid ${theme.palette.principal.grey50}`,
                borderRadius: 2,
                padding: 2,
                marginTop: 1,
              }}
            >
              <Stack gap={1}>
                {instalments.map((instalment, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                      '&:not(:last-child)': {
                        paddingBottom: 0.5,
                        borderBottom: '1px solid',
                        borderColor: theme.palette.principal.grey50,
                      },
                    }}
                  >
                    <Typography variant="Body S">{instalment.date}</Typography>
                    <Typography variant="Body M bold">
                      {formatCurrency(instalment.amount, currency)}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Box>
          </>
        )}
      </Stack>
      {remainingAmount > 0 && (
        <>
          {isPaymentPlan ? (
            <Typography variant="Body S">
              Your payment plan is in progress. If you&apos;d like to settle it now, or
              pay a one off instalment to reduce your future auto debit payments, click
              the button below.
            </Typography>
          ) : (
            <Typography variant="Body S">
              {`Your balance will automatically be settled on ${BNPLPaymentDate}. If you’d like to settle it now, or pay an instalment click the button below.`}
            </Typography>
          )}

          <Button
            variant="outlined"
            sx={{ width: 'fit-content' }}
            onClick={() =>
              navigate(CheckoutRoutePath.BALANCE_PAYMENT.generatePath(tripId))
            }
          >
            Make a payment now
          </Button>
        </>
      )}
    </Stack>
  );
};
