import React from 'react';
import {
  Box,
  Grid,
  PaymentOptionAmexIcon,
  PaymentOptionMastercardIcon,
  PaymentOptionVisaIcon,
  ProtectionLicenseAbtaIcon,
  ProtectionLicenseAtolIcon,
  SocialMediaFacebookIcon,
  SocialMediaInstagramIcon,
  SocialMediaLinkedInIcon,
  SocialMediaPinterestIcon,
  SocialMediaTikTokIcon,
  SocialMediaXIcon,
  Stack,
  Typography,
  styled,
} from 'design-system';
import { pageHorizontalPadding, pageMaxWidthPx } from '@src/shared/Layout/Layout';
import { FooterListItem, FooterSection } from './FooterListSection';
import { SocialMediaLink, SocialMediaLinks } from './SocialMediaLinks';

export const SectionHeader = styled(Typography)(({}) => ({
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '20px',
  letterSpacing: '4px',
  textTransform: 'uppercase',
}));

const aboutUsListItems: Array<FooterListItem> = [
  { title: 'About us', linkHref: '#' },
  { title: 'Your Pack Leaders', linkHref: '#' },
  { title: 'The Flash Pack Foundation', linkHref: '#' },
  { title: 'Careers at Flash Pack', linkHref: '#' },
  { title: 'Contact us', linkHref: '#' },
];

const ourPoliciesListItems: Array<FooterListItem> = [
  { title: 'Flash Pack safety', linkHref: '#' },
  { title: 'Image policy guidelines', linkHref: '#' },
  { title: 'Terms and conditions', linkHref: '#' },
  { title: 'Privacy policy', linkHref: '#' },
];

const socialMediaLinks: Array<SocialMediaLink> = [
  {
    icon: <SocialMediaFacebookIcon />,
    linkHref: 'https://www.facebook.com/theflashpack',
  },
  {
    icon: <SocialMediaInstagramIcon />,
    linkHref: 'https://www.instagram.com/flashpack/',
  },
  {
    icon: <SocialMediaLinkedInIcon />,
    linkHref: 'https://www.linkedin.com/company/the-flash-pack/',
  },
  {
    icon: <SocialMediaPinterestIcon />,
    linkHref: 'https://www.pinterest.pt/theflashpack/',
  },
  {
    icon: <SocialMediaTikTokIcon />,
    linkHref: 'https://twitter.com/flashpack',
  },
  {
    icon: <SocialMediaXIcon />,
    linkHref: 'https://www.tiktok.com/@flashpacktravel',
  },
];

const GAP_BETWEEN_SECTIONS_XS_SCREEN = 5;
const GAP_BETWEEN_SECTIONS_SM_SCREEN = 3;
const GAP_BETWEEN_SECTIONS_MD_SCREEN = 3;

export const Footer: React.FC = () => {
  return (
    <Box
      component={'footer'}
      sx={{
        // to keep the border throughout the whole page width
        // we declare border on this level
        // and max-width on the child
        borderTop: '1px solid',
        borderColor: 'principal.grey50',
      }}
    >
      <Box
        // not to interfere with grid spacing magic we declare padding on this level
        sx={{
          maxWidth: pageMaxWidthPx,
          marginX: 'auto',
          marginY: 10,
          paddingX: pageHorizontalPadding,
        }}
      >
        <Grid
          // and grid is responsible for alignment only
          container
          spacing={{
            xs: GAP_BETWEEN_SECTIONS_XS_SCREEN,
            sm: GAP_BETWEEN_SECTIONS_SM_SCREEN,
            md: GAP_BETWEEN_SECTIONS_MD_SCREEN,
          }}
        >
          <Grid item xs={12} sm={4} md={4}>
            <Stack
              gap={{
                xs: GAP_BETWEEN_SECTIONS_XS_SCREEN,
                sm: GAP_BETWEEN_SECTIONS_SM_SCREEN,
                md: GAP_BETWEEN_SECTIONS_MD_SCREEN,
              }}
            >
              <FooterSection sectionTitle={'About us'} listItems={aboutUsListItems} />
              <FooterSection
                sectionTitle={'Social media'}
                postListContent={<SocialMediaLinks socialMediaLinks={socialMediaLinks} />}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <Stack
              gap={{
                xs: GAP_BETWEEN_SECTIONS_XS_SCREEN,
                sm: GAP_BETWEEN_SECTIONS_SM_SCREEN,
                md: GAP_BETWEEN_SECTIONS_MD_SCREEN,
              }}
              justifyContent={'space-between'}
              height={'100%'}
            >
              <FooterSection
                sectionTitle={'Our policies'}
                listItems={ourPoliciesListItems}
              />
              <FooterSection
                sectionTitle={'Payment options'}
                postListContent={
                  <Stack
                    gap={{
                      xs: 1,
                      sm: 1,
                      md: 2,
                      lg: 4,
                    }}
                    direction={'row'}
                  >
                    <PaymentOptionVisaIcon
                      sx={{ height: 32, width: 'auto', maxWidth: '33%' }}
                    />
                    <PaymentOptionMastercardIcon
                      sx={{ height: 32, width: 'auto', maxWidth: '33%' }}
                    />
                    <PaymentOptionAmexIcon
                      sx={{ height: 32, width: 'auto', maxWidth: '33%' }}
                    />
                  </Stack>
                }
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <FooterSection
              sectionTitle={'Book with confidence'}
              postListContent={
                <Stack gap={2}>
                  <Stack
                    gap={{
                      xs: 2,
                      sm: 2,
                      md: 5,
                      lg: 5,
                    }}
                    direction={'row'}
                    alignItems={'center'}
                  >
                    <ProtectionLicenseAtolIcon
                      sx={{
                        height: {
                          xs: 80,
                          sm: 60,
                          md: 80,
                        },
                        width: 'auto',
                      }}
                    />
                    <ProtectionLicenseAbtaIcon
                      sx={{
                        height: {
                          xs: 60,
                          sm: 45,
                          md: 60,
                        },
                        width: 'auto',
                      }}
                    />
                  </Stack>
                  <Typography
                    variant="label_Figma"
                    color={'principal.grey70'}
                    whiteSpace={'break-spaces'}
                  >
                    Flight-inclusive Packages are financially protected by the ATOL scheme
                    - ATOL number 12040.
                    {'\n'}
                    {'\n'}
                    Flash Pack Travel are a UK registered company - 12734022 Address: 4th
                    Floor, Silverstream House, 45 Fitzroy Street, Fitzrovia, London,
                    United Kingdom W1T 6EB
                  </Typography>
                </Stack>
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
