import React from 'react';
import { Button, CommentsIcon, Stack, Typography } from 'design-system';

export const SpeakToExpert = () => {
  return (
    <Stack gap={3} bgcolor="system.green10" padding={4} borderRadius={3}>
      <Stack direction="row" gap={4}>
        <Stack gap={2}>
          <Typography variant="H5">Speak to a trip expert</Typography>
          <Typography variant="Body S">
            If you still have questions about your perfect trip, we’re here to help.
          </Typography>
        </Stack>

        <CommentsIcon sx={{ width: 51, height: 51, color: 'transparent' }} />
      </Stack>

      <Button
        variant="secondary"
        onClick={() => {
          if (window.Intercom) {
            window.Intercom('show');
          } else {
            window.open('https://www.flashpack.com/contact-flash-pack/', '_blank');
          }
        }}
      >
        Contact us
      </Button>
    </Stack>
  );
};
