import { FormDropdown, Validator } from 'design-system';

const genderPassportOptions = [
  {
    label: 'Male',
    value: 'M',
  },
  {
    label: 'Female',
    value: 'F',
  },
  {
    label: 'Unspecified/Other',
    value: 'O',
  },
];

export const GenderDropdown = () => {
  return (
    <FormDropdown
      name="gender"
      options={genderPassportOptions}
      validate={Validator.required}
      TextInputProps={{
        size: 'large',
        label: 'Gender (as on passport)',
        placeholder: 'Select...',
        fullWidth: true,
        testid: 'create-account-gender',
        inputProps: {
          autoComplete: 'nope',
        },
      }}
    />
  );
};
