import { FC, useEffect, useState } from 'react';
import { BaseInputProps, WarningIcon } from 'design-system';
import { Stack, Box } from '@mui/material';
import { Typography } from '../../typography';
import Dropdown from '../Dropdown/Dropdown';
import { NumberInput } from '../NumberInput/NumberInput';
import { getYear, isValid, parse } from 'date-fns';

export type DateInputProps = {
  value?: string | null;
  onChange: (newValue: string | null) => void;
} & Omit<BaseInputProps, 'onChange' | 'value'>;

export const isValidDateFormat = (dateString: string): boolean => {
  // check if the string matches the format yyyy-mm-dd
  const regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
  if (!regex.test(dateString)) {
    return false;
  }
  const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());

  return isValid(parsedDate) && getYear(parsedDate) >= 1900;
};

export const DateInput: FC<DateInputProps> = ({
  name,
  value,
  onChange,
  label,
  helperText,
  error,
  testid,
  onFocus,
  onBlur,
}) => {
  const [day, setDay] = useState<string>('');
  const [month, setMonth] = useState<string>('');
  const [year, setYear] = useState<string>('');

  const isValidOnChange = (year: string, month: string, day: string) => {
    if (year && month && day) {
      const paddedDay = day.padStart(2, '0');
      if (isValidDateFormat(`${year}-${month}-${paddedDay}`)) {
        onChange(`${year}-${month}-${paddedDay}`);
      } else {
        onChange(null);
      }
    } else {
      onChange(null);
    }
  };

  const handleChange = (newValue: string, field: string) => {
    switch (field) {
      case 'day':
        setDay(newValue);
        isValidOnChange(year, month, newValue);
        break;
      case 'month':
        setMonth(newValue);
        isValidOnChange(year, newValue, day);
        break;
      case 'year':
        setYear(newValue);
        isValidOnChange(newValue, month, day);
        break;
    }
  };

  useEffect(() => {
    if (value) {
      const [year, month, day] = value.split('-');
      setDay(day.replace(/^0/, '')); // Remove leading zero
      setMonth(month);
      setYear(year);
    }
  }, [value]);

  return (
    <Box>
      <Box
        sx={{
          marginBottom: 0.5,
          color: error ? 'system.red100' : 'principal.black',
          display: 'flex',
        }}
        id={`date-label-${name}`}
      >
        {error && (
          <WarningIcon sx={{ height: '12px', marginY: 'auto', marginLeft: -0.5 }} />
        )}

        <Typography variant="Body S" sx={{ textTransform: 'none' }}>
          {label}
        </Typography>
      </Box>
      <Stack display={'flex'} flexDirection={'row'} gap={1}>
        <Box>
          <NumberInput
            value={day}
            description="Day"
            size="small"
            placeholder="DD"
            inputProps={{ maxLength: 2 }}
            sx={{
              width: '56px',
            }}
            name="day"
            error={!!error}
            onChange={(e) => {
              handleChange(e.target.value, 'day');
            }}
            testid={`${testid}-day`}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </Box>

        <Dropdown
          TextInputProps={{
            description: 'Month',
            size: 'small',
            error: !!error,
            testid: `${testid}-month`,
            placeholder: 'Select...',
          }}
          name="month"
          value={monthOptions.find((m) => m.value === month) || null}
          onChange={(_event: unknown, newValue: OptionType | null) => {
            if (newValue) {
              handleChange(newValue.value, 'month');
            }
          }}
          options={monthOptions}
        />
        <Box>
          <NumberInput
            value={year}
            description="Year"
            name="year"
            size="small"
            error={!!error}
            onChange={(e) => {
              handleChange(e.target.value, 'year');
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            sx={{ width: '76px' }}
            placeholder="YYYY"
            inputProps={{ maxLength: 4 }}
            testid={`${testid}-year`}
          />
        </Box>
      </Stack>
      {helperText && (
        <Box sx={{ marginTop: 0.5 }} id={`helper-text-label-${name}`}>
          <Typography
            variant="Additional"
            color={error ? 'system.red100' : 'principal.grey70'}
            fontStyle={'italic'}
          >
            {helperText}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

type OptionType = {
  label: string;
  value: string;
};

const monthOptions = [
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];
