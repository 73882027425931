import { Typography } from 'design-system';

export const Contacts: React.FC = () => {
  return (
    <Typography variant={'Body S'} whiteSpace={'pre'} textAlign={'center'}>
      If you have any questions, please contact us on:{'\n'}
      UK: +44(0)20 3936 1998{'\n'}
      US: +1 760 284 6975{'\n'}
      Email: readytoescape@flashpack.com{'\n'}
    </Typography>
  );
};
