import { useMemo } from 'react';
import Decimal from 'decimal.js';
import { Basket } from '@flashpack/graphql';
import { ToPayLineItem } from '@src/pay/ToPayWidget';

interface PaymentBreakdown {
  tripPrice: ToPayLineItem;
  tripTotal: ToPayLineItem;
  promoDiscount: ToPayLineItem;
  extrasPayItems: Array<ToPayLineItem>;
  insiderDiscount: ToPayLineItem | null;
  earlyBirdDiscount: ToPayLineItem | null;
  promotionalDiscount: ToPayLineItem | null;
}

/**
 * A custom hook that calculates the payment breakdown for a given basket.
 */
export const usePaymentBreakdown = (basket: Basket | null): PaymentBreakdown | null => {
  return useMemo(() => {
    if (!basket) {
      return null;
    }

    const tripPrice: ToPayLineItem = {
      label: 'Trip price',
      price: basket.tripPrice,
    };

    const extrasPayItems = (basket.extras ?? []).map<ToPayLineItem>((extra) => ({
      label: extra.name,
      price: new Decimal(extra.price).toNumber(),
    }));

    const promoDiscountValue = (basket.appliedPromocodes ?? []).reduce(
      (acc, promo) => acc - Number(promo.discountValue),
      0,
    );
    const promoDiscount = {
      label: 'Promo code',
      price: promoDiscountValue,
    };

    const insiderDiscount = basket.insiderDiscount
      ? {
          label: 'Insider discount',
          price: -basket.insiderDiscount,
        }
      : null;

    const earlyBirdDiscount = basket.earlyBirdDiscount
      ? {
          label: 'Early bird discount',
          price: -basket.earlyBirdDiscount,
        }
      : null;

    const promotionalDiscount = basket.promotionalDiscount
      ? {
          label: 'Promotional discount',
          price: -basket.promotionalDiscount,
        }
      : null;

    const tripTotal = {
      label: 'Trip total',
      price: basket.tripTotal,
    };

    return {
      tripPrice,
      tripTotal,
      promoDiscount,
      extrasPayItems,
      insiderDiscount,
      earlyBirdDiscount,
      promotionalDiscount,
    };
  }, [basket]);
};
