import React from 'react';
import { Field } from 'react-final-form';
import { FieldValidator } from 'final-form';
import { NumberInput } from './NumberInput';
import { BaseInputProps } from '../BaseInput';

interface FormNumberInputProps extends BaseInputProps {
  name: string;
  parse?: (value: string, name: string) => number;
  format?: (value: number, name: string) => string;
  validate?: FieldValidator<number>;
  numberInputProps?: Omit<BaseInputProps, 'name' | 'value' | 'onChange'>;
}

const defaultNumberParser: FormNumberInputProps['parse'] = (value, _name) => {
  return parseFloat(value);
};

const defaultNumberFormatter: FormNumberInputProps['format'] = (value, _name) => {
  return String(value);
};

export const FormNumberInput: React.FC<FormNumberInputProps> = ({
  name,
  parse = defaultNumberParser,
  format = defaultNumberFormatter,
  validate,
  helperText,
  numberInputProps,
}) => {
  return (
    <Field<number, HTMLElement, string>
      name={name}
      parse={parse}
      format={format}
      validate={validate}
      render={({ input, meta }) => {
        return (
          <NumberInput
            {...input}
            {...numberInputProps}
            name={name}
            error={meta.touched && !!meta.error}
            helperText={helperText || (meta.error as string)}
          />
        );
      }}
    />
  );
};
