import { useCustomerAuthorization } from '@src/auth/AuthorizationProvider';
import { Outlet, Navigate } from 'react-router-dom';

export const ProtectedRoute = ({
  children,
  navigateTo = 'login',
}: {
  children?: JSX.Element;
  navigateTo?: string;
  relative?: boolean;
}) => {
  const { currentUser, loadingAuthorization } = useCustomerAuthorization();

  if (loadingAuthorization) {
    return null;
  }

  if (!currentUser) {
    return (
      <Navigate
        to={navigateTo + location.search}
        state={{
          redirectBack: location.pathname + location.search,
        }}
        replace
      />
    );
  }

  return children ? children : <Outlet />;
};
