import { ApolloError } from '@apollo/client';

export const hasAnyErrorWithCode = (error?: unknown, code?: string): boolean => {
  if (!error || !code) {
    return false;
  }

  if (!(error instanceof ApolloError)) {
    return false;
  }

  return !!error.graphQLErrors.find((x) => x.extensions.code == code);
};
