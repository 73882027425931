import { CustomerCurrency, PaymentOptionType } from '@flashpack/graphql';
import { addMonths, format, subDays, subMonths } from 'date-fns';

export interface Instalment {
  date: string;
  dateYyyyMmDd: string;
  amount: number;
}

export interface CalculatorOptions {
  totalCost?: number;
  currency?: CustomerCurrency;
  paymentOption?: PaymentOptionType;
  departureDate?: string;
  depositAmount?: number;
  paymentPlanStartDate?: string;
}

export interface CalculatorResult {
  instalments: Instalment[];
  startDate: Date;
  error?: string;
}

export class InstalmentsCalculator {
  private options: CalculatorOptions;
  private plans = {
    [PaymentOptionType.ThreeMonthsPlan]: { instalments: 3 },
    [PaymentOptionType.SixMonthsPlan]: { instalments: 6 },
  };

  constructor(options: CalculatorOptions) {
    this.options = options;
  }

  calculate(): CalculatorResult {
    const {
      totalCost,
      paymentOption,
      departureDate,
      depositAmount,
      paymentPlanStartDate,
    } = this.options;

    if (!totalCost || !paymentOption || !departureDate || !depositAmount) {
      return {
        instalments: [],
        startDate: new Date(),
        error: 'Missing required options.',
      };
    }

    if (paymentOption === PaymentOptionType.BookNowPayLater) {
      return {
        instalments: [],
        startDate: new Date(),
      };
    }

    if (paymentOption === PaymentOptionType.FullBalance) {
      return {
        instalments: [],
        startDate: new Date(),
      };
    }

    const plan = this.plans[paymentOption];

    const latestStartDate = new Date(
      getPaymentPlanLatestStartDate({
        departureDate,
        paymentOption,
      }),
    );

    let startDate: Date;
    if (paymentPlanStartDate) {
      startDate = new Date(paymentPlanStartDate);
    } else {
      startDate = latestStartDate;
    }

    if (startDate > latestStartDate) {
      return {
        instalments: [],
        startDate,
        error: `Date is too close to your trip. It must be before ${format(
          latestStartDate,
          'd MMM yy',
        )}.`,
      };
    }

    const remainingBalance = totalCost - depositAmount;
    let workingTotal = 0;

    const allInstalments: Instalment[] = [];

    for (let i = 0; i < plan.instalments; i++) {
      let amount: number;

      if (i === plan.instalments - 1) {
        // final instalment adds the remainder
        amount = remainingBalance - workingTotal;
      } else {
        amount = Number((remainingBalance / plan.instalments).toFixed(2));
      }

      workingTotal += amount;

      const instalmentDate = addMonths(startDate, i);

      allInstalments.push({
        date: format(instalmentDate, 'd MMM yy'),
        dateYyyyMmDd: format(instalmentDate, 'yyyy-MM-dd'),
        amount: amount,
      });
    }

    return {
      instalments: allInstalments,
      startDate,
    };
  }
}

export const getPaymentPlanLatestStartDate = ({
  departureDate,
  paymentOption,
}: {
  departureDate: string;
  paymentOption: PaymentOptionType;
}): string => {
  const instalments = paymentOption === PaymentOptionType.ThreeMonthsPlan ? 3 : 6;

  const departureDateObj = new Date(departureDate);
  const threshold = subDays(departureDateObj, 60);
  const latestStartDate = subMonths(threshold, instalments - 1);

  return format(latestStartDate, 'yyyy-MM-dd');
};
