import { useMutation } from '@apollo/client';
import { Field, Form } from 'react-final-form';
import {
  CustomerCurrency,
  PaymentOptionType,
  UpdateBasketDocument,
  UpdateCustomerBillingDetailsDocument,
} from '@flashpack/graphql';
import {
  Box,
  composeValidators,
  createPrioritizedCountryOptions,
  Divider,
  FormDropdown,
  FormTextInput,
  GenericError,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Validator,
  ValidatorFactory,
} from 'design-system';
import BookDepartureHeader from '@src/shared/book-departure-header/BookDepartureHeader';
import ReservationTimer from './reservation-timer/ReservationTimer';
import Layout from '@src/shared/Layout/Layout';
import StepperPill from '@src/shared/stepper-pill/StepperPill';
import { LoadingButton } from 'design-system';
import { JoinWhatsAppFormField } from '@src/shared/join-whatsapp/JoinWhatsAppFormField';
import ToPayWidget from '@src/pay/ToPayWidget';
import { SpeakToExpert } from '@src/shared/support-chat/SpeakToExpert';
import PromotionalCode from './promotional-code/PromotionalCode';
import { useBasket, useExpiredReservationRedirect, useRouting } from '@src/shared/hooks';
import { Extras } from '@src/shared/extras/Extras';
import { useOrderDetails } from '@src/shared/hooks/useOrderDetails';
import { OrderDetails } from '@src/shared/order-details/OrderDetails';
import { PageFormTitle } from '@src/shared/page-form-title/PageFormTitle';
import { ResponsiveSideBySide } from '@src/shared/responsive-side-by-side/ResponsiveSideBySide';
import { PageFormLoader } from '@src/shared/page-form-loader/PageFormLoader';
import { useEffect, useMemo, useState } from 'react';
import { trackCheckoutEventStep1 } from '@src/gtm/tracking';
import { useCustomerAuthorization } from '@src/auth/AuthorizationProvider';
import { PaymentOptions } from '@src/book-departure/payment-options/PaymentOptions';
import { PaymentModal } from '@src/pay/PaymentModal';
import { BookingTermsAndConditionsFormField } from '@src/shared/booking-terms-and-conditions/BookingTermsAndConditionsFormField';

export type BookDepartureFormValues = {
  joinWhatsAppGroup: boolean;
  acceptedTermsAndConditions: boolean;
  // we use plain form values structure in forms to avoid TS errors
  selectedPaymentOptionType: string;
  selectedPaymentOptionDepositAmount: number | null;
  selectedPaymentOptionStartDate: string | null;
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  postcode: string;
};

const prioritizedCountryOptions = createPrioritizedCountryOptions(['us', 'gb']).map(
  (option) => ({
    label: option.label,
    value: option.value.toUpperCase(),
  }),
);

export const BookDeparturePage = () => {
  const { queryParams } = useRouting<{ departureCode: string; currencyCode: string }>();
  const { departureCode, currencyCode } = queryParams;

  const { loading: loadingBasket, error, basket } = useBasket();
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const { currentUser } = useCustomerAuthorization();
  const [updateBillingDetails] = useMutation(UpdateCustomerBillingDetailsDocument);
  const [updateBasket] = useMutation(UpdateBasketDocument);

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const { data: orderDetailsData, loading: orderDetailsLoading } = useOrderDetails();

  const customerCurrency = currencyCode.toLowerCase() as CustomerCurrency;
  const termsAndConditions = basket?.terms?.content;

  useExpiredReservationRedirect({
    skip: loadingBasket || !basket?.reservation,
    reservationExpiryDate: basket?.reservation?.expiresAt,
  });

  useEffect(() => {
    if (
      !loadingBasket &&
      !orderDetailsLoading &&
      currentUser &&
      basket &&
      orderDetailsData?.orderDetails
    ) {
      trackCheckoutEventStep1(basket, orderDetailsData?.orderDetails, currentUser);
    }
  }, [
    loadingBasket,
    orderDetailsLoading,
    basket,
    orderDetailsData?.orderDetails,
    currentUser,
  ]);

  const initialValues = useMemo(() => {
    const paymentOptionsAvailable = basket?.availablePaymentOptions?.length !== 0;

    const selectedPaymentOptionIsAvailable =
      paymentOptionsAvailable &&
      basket?.availablePaymentOptions.find(
        (option) => option.type === basket?.selectedPaymentOption?.optionType,
      );

    const selectedPaymentOptionType = selectedPaymentOptionIsAvailable
      ? basket?.selectedPaymentOption?.optionType
      : PaymentOptionType.FullBalance;

    return {
      joinWhatsAppGroup: basket?.joinWhatsAppGroup,
      selectedPaymentOptionType,
      selectedPaymentOptionDepositAmount:
        basket?.selectedPaymentOption?.depositAmount || basket?.tourDeposit,
    };
  }, [basket]);

  if (loadingBasket) {
    return (
      <Layout
        HeroComponent={
          <BookDepartureHeader location={orderDetailsData?.orderDetails.location} />
        }
      >
        <PageFormLoader />
      </Layout>
    );
  }

  if (error || !basket) {
    return (
      <GenericError
        error={'Something went wrong while creating your order. Please try again later.'}
      />
    );
  }

  const continueToPayment = async (values: BookDepartureFormValues) => {
    const {
      selectedPaymentOptionType,
      selectedPaymentOptionDepositAmount,
      selectedPaymentOptionStartDate,
    } = values;

    const depositAmount =
      selectedPaymentOptionType === PaymentOptionType.FullBalance
        ? null
        : selectedPaymentOptionDepositAmount;

    await Promise.all([
      updateBillingDetails({
        variables: {
          input: {
            address: {
              addressLine1: values.addressLine1,
              addressLine2: values.addressLine2,
              city: values.city,
              countryCode: values.country,
              postCode: values.postcode,
            },
          },
        },
      }),
      updateBasket({
        variables: {
          input: {
            id: basket.id,
            departureCode,
            acceptedTermsAndConditions: values.acceptedTermsAndConditions,
            joinWhatsAppGroup: values.joinWhatsAppGroup,
            selectedPaymentOption: {
              optionType: selectedPaymentOptionType as PaymentOptionType,
              depositAmount,
              paymentPlan: selectedPaymentOptionStartDate
                ? {
                    startDate: new Date(selectedPaymentOptionStartDate).toISOString(),
                  }
                : null,
            },
          },
        },
      }),
    ]);

    setIsPaymentModalOpen(true);
  };

  const departureDate = basket.departureDate;

  return (
    <Layout
      HeroComponent={
        <BookDepartureHeader location={orderDetailsData?.orderDetails.location} />
      }
    >
      <PaymentModal
        open={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
      />
      <ResponsiveSideBySide
        mainSection={
          <Stack gap={3}>
            <StepperPill currentStep={2} totalSteps={3} />
            <PageFormTitle>Select your options</PageFormTitle>
            <Stack gap={1}>
              {basket.reservation && (
                <ReservationTimer endTime={new Date(basket.reservation.expiresAt)} />
              )}
            </Stack>

            <Extras basket={basket} />

            <Form<BookDepartureFormValues>
              onSubmit={(values) => {
                return continueToPayment(values);
              }}
              initialValues={initialValues}
              render={({ handleSubmit, submitting, form, values }) => {
                return (
                  <form
                    id="checkout-book"
                    onSubmit={(e) => {
                      void handleSubmit(e);
                    }}
                  >
                    <Stack gap={3}>
                      <Field name="joinWhatsAppGroup" type="checkbox">
                        {(props) => <JoinWhatsAppFormField {...props} />}
                      </Field>

                      <Divider sx={{ m: 0 }} />

                      {orderDetailsData?.orderDetails.promoCodesEnabled && (
                        <PromotionalCode
                          basketId={basket.id}
                          currencyCode={currencyCode}
                          departureCode={departureCode}
                          appliedPromocodes={basket.appliedPromocodes}
                        />
                      )}

                      <PaymentOptions
                        basket={basket}
                        departureDate={departureDate ?? undefined}
                        paymentPlanStartDate={values.selectedPaymentOptionStartDate}
                        form={form}
                      />

                      <Stack gap={2}>
                        <Typography variant="H5">Billing address</Typography>
                        <FormTextInput
                          name="addressLine1"
                          validate={Validator.required}
                          textInputProps={{
                            placeholder: 'Enter address...',
                            label: 'Address line 1',
                            size: 'large',
                          }}
                        />
                        <FormTextInput
                          name="addressLine2"
                          textInputProps={{
                            placeholder: 'Enter address...',
                            label: 'Address line 2',
                            size: 'large',
                          }}
                        />
                        <FormTextInput
                          name="city"
                          validate={Validator.required}
                          textInputProps={{
                            placeholder: 'Enter town/city...',
                            label: 'Town/City',
                            size: 'large',
                          }}
                        />
                        <FormTextInput
                          name="postcode"
                          textInputProps={{
                            placeholder: 'Enter code...',
                            label: 'Postcode/Zipcode',
                          }}
                          validate={composeValidators(
                            Validator.required,
                            ValidatorFactory.createMaxLength(10),
                          )}
                        />
                        <FormDropdown
                          name="country"
                          options={prioritizedCountryOptions}
                          validate={Validator.required}
                          TextInputProps={{
                            size: 'large',
                            label: 'Country',
                            placeholder: 'Select country...',
                            testid: 'select-country',
                            inputProps: {
                              autoComplete: 'nope',
                            },
                          }}
                        />

                        {termsAndConditions && (
                          <Box mt={1}>
                            <Field
                              name="acceptedTermsAndConditions"
                              type="checkbox"
                              validate={Validator.required}
                            >
                              {(props) => (
                                <BookingTermsAndConditionsFormField
                                  termsAndConditionsHTML={termsAndConditions}
                                  {...props}
                                />
                              )}
                            </Field>
                          </Box>
                        )}
                      </Stack>

                      <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        data-testid="continue-to-payment"
                        sx={{ marginTop: '16px', alignSelf: 'self-start' }}
                        loading={submitting}
                      >
                        Continue
                      </LoadingButton>
                    </Stack>
                  </form>
                );
              }}
            />
          </Stack>
        }
        additionalSection={
          <Stack gap={3}>
            {(isMediumScreen || isLargeScreen) && (
              <OrderDetails
                loading={orderDetailsLoading}
                orderDetails={orderDetailsData?.orderDetails}
              />
            )}

            <ToPayWidget currency={customerCurrency} basket={basket} />
            <SpeakToExpert />
          </Stack>
        }
      />
    </Layout>
  );
};
