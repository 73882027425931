import { FieldRenderProps } from 'react-final-form';
import { FC } from 'react';
import { PhoneInput, PhoneNumberFieldProps } from './PhoneInput';

type AllowedPhoneNumberFieldProps = Omit<PhoneNumberFieldProps, 'value' | 'setValue'>;

type PhoneNumberFormFieldPropTypes = FieldRenderProps<string> &
  AllowedPhoneNumberFieldProps;

export const FormPhoneInput: FC<PhoneNumberFormFieldPropTypes> = (props) => {
  const { input, meta, ...rest } = props;
  const showError = !!meta.touched && !!meta.error;

  return (
    <PhoneInput
      {...rest}
      value={input.value}
      setValue={input.onChange}
      data-testid="phone-number-form-field"
      helperText={showError ? (meta.error as string) : ''}
      error={showError}
      callingCodeInputProps={{
        ...rest.callingCodeInputProps,
      }}
      numberInputProps={{
        ...rest.numberInputProps,
        error: showError,
        name: input.name,
        testid: input.name,
        InputProps: {
          onFocus: input.onFocus,
          onBlur: input.onBlur,
        },
      }}
    />
  );
};
