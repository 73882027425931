import { PaymentOption, PaymentOptionType } from '@flashpack/graphql';

export const isOnlyFullBalanceOptionAvailable = (
  paymentOptions: PaymentOption[],
): boolean => {
  return (
    paymentOptions.length === 1 &&
    paymentOptions[0].type === PaymentOptionType.FullBalance
  );
};

export const getDefaultPaymentOptionType = (
  availablePaymentOptions: PaymentOption[],
  selectedPaymentOptionType?: PaymentOptionType,
): PaymentOptionType => {
  // 1. If a payment option is already selected, return it
  // 2. If BookNowPayLater is available, return it
  // 3. Return the first available payment
  return (
    selectedPaymentOptionType ||
    availablePaymentOptions.find(
      (option) => option.type === PaymentOptionType.BookNowPayLater,
    )?.type ||
    availablePaymentOptions[0].type
  );
};
