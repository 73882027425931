import { FC } from 'react';
import { Basket, CustomerCurrency, PaymentOptionType } from '@flashpack/graphql';
import {
  composeValidators,
  FormNumberInput,
  RadioAccordionGroupOption,
  Stack,
  Typography,
  Validator,
  ValidatorFactory,
} from 'design-system';
import { formatCurrency } from '@src/shared/utils/currencyUtils';
import { formatDateToOrdinalMonthYear } from '@src/shared/dateUtils';
import { subDays } from 'date-fns';

export const BuyNowPayLaterOptionContent: FC<{
  tourDeposit: number;
  remainingPaymentAfterDeposit: number;
  departureDate?: string;
  basket: Basket;
}> = (props) => {
  const { tourDeposit, remainingPaymentAfterDeposit, departureDate, basket } = props;

  const currencyLabel = formatCurrency(
    remainingPaymentAfterDeposit,
    basket.currency as CustomerCurrency,
  );
  // date when deposit will be taken: 90 days before departure date
  const depositDate = departureDate
    ? subDays(new Date(departureDate), 90).toISOString()
    : null;
  const dateLabel = depositDate ? ` on ${formatDateToOrdinalMonthYear(depositDate)}` : '';

  return (
    <Stack gap={1}>
      <FormNumberInput
        name="selectedPaymentOptionDepositAmount"
        data-testid="deposit-amount"
        validate={composeValidators(
          Validator.required,
          ValidatorFactory.createMinNumber(tourDeposit),
          ValidatorFactory.createMaxNumber(basket.tripTotal),
        )}
        numberInputProps={{
          label: `Deposit Amount (minimum ${tourDeposit})`,
          placeholder: 'Enter deposit amount',
        }}
      />
      <Typography variant="Body S">
        Secure your place on the trip with a deposit today and the remaining{' '}
        <b>{currencyLabel}</b> will be taken automatically
        {dateLabel}. We’ll send you a reminder before this happens.
      </Typography>
    </Stack>
  );
};

export const createBuyNowPayLaterRadioGroupOption: (params: {
  tourDeposit: number;
  remainingPaymentAfterDeposit: number;
  departureDate?: string;
  basket: Basket;
}) => RadioAccordionGroupOption = (params) => {
  const { tourDeposit, remainingPaymentAfterDeposit, departureDate, basket } = params;

  return {
    value: PaymentOptionType.BookNowPayLater,
    label: 'Book your place with a deposit',
    content: (
      <BuyNowPayLaterOptionContent
        tourDeposit={tourDeposit}
        remainingPaymentAfterDeposit={remainingPaymentAfterDeposit}
        departureDate={departureDate}
        basket={basket}
      />
    ),
  };
};
