import ReactDOM from 'react-dom/client';
import App from '@src/App';
import { initSentry } from './sentry';
import { Environment, environmentIs } from '@src/environment';

initSentry();

const root = document.getElementById('root');

if (root) {
  if (environmentIs(Environment.Test)) {
    ReactDOM.createRoot(root).render(<App />);
  } else {
    ReactDOM.createRoot(root).render(<App />);
  }
}
