import { useCallback } from 'react';
import { useRouting } from './useRouting';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';

export const useSoldOutRedirect = () => {
  const { navigate } = useRouting();

  const runRedirect = useCallback(() => {
    navigate(CheckoutRoutePath.SOLD_OUT.value);
  }, [navigate]);

  return {
    runRedirect,
  };
};
