import React, { useCallback, useState } from 'react';
import { Typography, Stack, LoadingButton } from 'design-system';
import { AirwallexDropInElement, CreatedIntent } from '@src/airwallex/AirwallexDropIn';
import { useLazyQuery } from '@apollo/client';
import { ClientPaymentSecretDocument } from '@flashpack/graphql';
import * as Sentry from '@sentry/browser';

export const UpdateCardDetailsDropIn: React.FC = () => {
  const [success, setSuccess] = useState(false);
  const [initialised, setInitialised] = useState(false);
  const [airwallexError, setAirwallexError] = useState(false);

  const [getClientSecret, { loading, error }] = useLazyQuery(ClientPaymentSecretDocument);

  const handleFetchClientSecret = async () => {
    const clientSecret = await getClientSecret();
    return clientSecret.data?.clientPaymentSecret as CreatedIntent;
  };

  const handleSuccess = () => {
    setSuccess(true);
  };

  const handleAirwallexError = useCallback((error: unknown) => {
    Sentry.captureException('Airwallex update payment method failed', {
      level: 'error',
      extra: { error: JSON.stringify(error) },
    });
    setAirwallexError(true);
  }, []);

  return (
    <Stack marginTop={2}>
      {!initialised && (
        <LoadingButton
          variant="outlined"
          sx={{ width: '200px' }}
          size="small"
          onClick={() => setInitialised(true)}
          disabled={loading}
          loading={loading}
        >
          Update
        </LoadingButton>
      )}
      {(error || airwallexError) && (
        <Typography variant="Body M" color="error">
          Error updating card details. Please try again.
        </Typography>
      )}
      {initialised && !success && !airwallexError && (
        <AirwallexDropInElement
          createIntent={handleFetchClientSecret}
          onSuccess={handleSuccess}
          onError={handleAirwallexError}
          dropInType="update"
        />
      )}
      {success && (
        <Typography variant="Body M">Card details updated successfully</Typography>
      )}
    </Stack>
  );
};
