import { Box, styled } from 'design-system';

export const TermsWrapper = styled(Box)(({}) => ({
  position: 'relative',
  width: 'calc(100% - 14px)',
  height: 240,
}));

export const TermsScrollablePart = styled('div')(({ theme }) => ({
  height: '100%',
  width: 'calc(100% + 14px)',
  overflow: 'auto',

  '&::-webkit-scrollbar': {
    width: 14,
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: 24,
    backgroundColor: theme.palette.principal.grey50,
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: 24,
    backgroundColor: theme.palette.principal.black,
  },
}));

export const TermsContent = styled(Box)(({ theme }) => ({
  whiteSpace: 'pre-line',
  backgroundColor: theme.palette.principal.grey30,
  padding: 24,
  marginRight: 8,
  overflowY: 'scroll',
  position: 'relative',

  '& p': {
    margin: 0,
  },
}));
