import React from 'react';
import { Box, Divider, Typography, useTheme, Stack } from 'design-system';
import { Basket, CustomerCurrency } from '@flashpack/graphql';
import { formatCurrency } from '../shared/utils/currencyUtils';
import { usePaymentBreakdown } from '@src/shared/hooks';

interface ToPayWidgetProps {
  currency: CustomerCurrency;
  basket: Basket | null;
}

type LineItem = {
  label: string;
  price: number;
};

// exportable alias - not to rename it
export type ToPayLineItem = LineItem;

export const ToPayWidget: React.FC<ToPayWidgetProps> = ({ basket, currency }) => {
  const theme = useTheme();
  const breakdown = usePaymentBreakdown(basket);

  if (!breakdown) {
    return null;
  }

  const {
    tripPrice,
    tripTotal,
    promoDiscount,
    extrasPayItems,
    insiderDiscount,
    earlyBirdDiscount,
    promotionalDiscount,
  } = breakdown;

  const hasOnlyTripPrice = Boolean(
    extrasPayItems.length === 0 &&
      !promoDiscount.price &&
      !insiderDiscount &&
      !earlyBirdDiscount &&
      !promotionalDiscount &&
      tripPrice.price === tripTotal.price,
  );

  const lineItems = hasOnlyTripPrice
    ? []
    : [
        tripPrice,
        ...extrasPayItems,
        earlyBirdDiscount,
        insiderDiscount,
        promoDiscount,
        promotionalDiscount,
      ].filter((item): item is ToPayLineItem => !!item && item.price !== 0);

  const atLeastOneLineItem = lineItems.length > 0;

  return (
    <Box
      sx={{
        borderRadius: 4,
        backgroundColor: theme.palette.principal.grey30,
        padding: 4,
      }}
    >
      {atLeastOneLineItem && (
        <>
          <Stack gap={'10px'}>
            {lineItems.map((lineItem) => (
              <Box
                key={lineItem.label}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="Body S">{lineItem.label}</Typography>
                <Typography variant="Body S">
                  {formatCurrency(lineItem.price, currency)}
                </Typography>
              </Box>
            ))}
          </Stack>
          <Divider sx={{ borderColor: 'principal.white', my: 2 }} />
        </>
      )}
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Typography variant="H4">{tripTotal.label}</Typography>
        <Typography variant="H4">{formatCurrency(tripTotal.price, currency)}</Typography>
      </Box>
    </Box>
  );
};

export default ToPayWidget;
